import { ApiSaleOrderService } from 'services';
import { AxiosResponse } from 'axios';
import { IProviderDTO } from './types';

const endpoints = {
	list: 'providers',
};

export const getProviderList = (
	page = 1,
	size = 15,
	search = '',
): Promise<
	AxiosResponse<{
		page: number;
		total: number;
		size: number;
		elements: IProviderDTO[];
	}>
> =>
	ApiSaleOrderService.get(endpoints.list, {
		params: {
			page,
			size,
			search,
			sort: 'fantasyName',
		},
	});

export const getProviderById = (id: string): Promise<AxiosResponse<IProviderDTO>> =>
	ApiSaleOrderService.get(`${endpoints.list}/${id}`);

export const putProvider = (body: Partial<IProviderDTO>): Promise<AxiosResponse<IProviderDTO>> =>
	ApiSaleOrderService.put(`${endpoints.list}/${body.id}`, body);
