import React, { useEffect } from 'react';
import {
	AppBar,
	Button,
	Collapse,
	Divider,
	Drawer,
	IconButton,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Toolbar,
	Typography,
	FormControl,
	Select,
	MenuItem,
	InputLabel,
	Box,
} from '@mui/material';

import { SelectChangeEvent } from '@mui/material/Select';
import itauPY from '../../../assets/country-flags/itauPY_flag.svg';
import bancard from '../../../assets/payment-processors/bancard.svg';
import MenuIcon from '@mui/icons-material/Menu';
import GroupsIcon from '@mui/icons-material/Groups';
import HomeIcon from '@mui/icons-material/Home';
import ListIcon from '@mui/icons-material/List';
import AddBoxIcon from '@mui/icons-material/AddBox';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import AddCardIcon from '@mui/icons-material/AddCard';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import HotelIcon from '@mui/icons-material/Hotel';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import SettingsIcon from '@mui/icons-material/Settings';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { CashIcon, TransferIcon } from '../customIcons';
import MevueloLogo from '../../../assets/mevuelo-blanco.svg';
import { ReactComponent as StatsIcon } from '../../../assets/stats-line-chart-svgrepo-com.svg';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import ImportExportIcon from '@mui/icons-material/ImportExport';

import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SvgIcon from '@mui/material/SvgIcon';

// @ts-ignore
import backgroundSound from '../../../assets/felo.mp3';

import { actions } from 'features/auth/slice';
import { RootState } from 'store';
import routes from 'constants/routes';
import { CookieService } from 'features/auth/services/CookieService';
import { ContentPaste, CurrencyExchange, ExpandLess, ExpandMore, PlaylistRemove } from '@mui/icons-material';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import PublicIcon from '@mui/icons-material/Public';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { CountryCodeEnum, OrderTypeEnum, orderTypes } from '../types';
import { getCountriesWithAgencyConfigForUser, isAdminOrSuperAdmin, isOperatorOrHigher } from '../../auth/helpers';

const useStyles = makeStyles((theme) => ({
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		lexGrow: 1,
	},
	countrySelector: {
		marginLeft: theme.spacing(3),
	},
	list: {
		width: 250,
	},
	toolbar: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	toolbarLeftside: {
		display: 'flex',
		alignItems: 'center',
	},
	logo: {
		verticalAlign: 'sub',
	},
	flagIcon: {
		width: '42px',
		margin: '4px',
	},
}));

interface Props {
	children: JSX.Element;
}

const audio = new Audio(backgroundSound);

export const Layout = (props: Props): JSX.Element => {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const [drawerOpen, setDrawerOpen] = React.useState(false);
	const [quotationsOpen, setQuotationsOpen] = React.useState(false);
	const [explorerOpen, setExplorerOpen] = React.useState(false);
	const [paymentOpen, setPaymentOpen] = React.useState(false);
	const [transactionsListOpen, setTransactionsListOpen] = React.useState(false);
	const [quotationOptionsOpen, setQuotationOptionsOpen] = React.useState(false);
	const [statsOpen, setStatsOpen] = React.useState(false);
	const { auth } = useSelector((state: RootState) => state);
	const handleMenuLink = (value: string) => () => history.push(value);
	const handleOrdersClick = (id: number) => history.push(routes.payment.order.replace(':id', `${id}`));
	const handleWireTransferCheckClick = () => history.push(routes.payment.wireTransferCheck);
	const handleOrderViajeNaranja = () => history.push(routes.payment.orderNaranja);
	const handleListViajeNaranjaBancardTransactionList = () =>
		history.push(routes.transactions.viajeNaranjaBancardTransactionList);
	const handleListViajeNaranjaPointTransactionList = () =>
		history.push(routes.transactions.viajeNaranjaItauPointTransactionList);
	const handleTransactionsClick = (orderTypeId: number) =>
		history.push(routes.transactions.gatewayTransactionList.replace(':orderTypeId', `${orderTypeId}`));
	const handleCountryChange = (e: SelectChangeEvent) => {
		const selectedCountry = e.target.value as CountryCodeEnum;
		dispatch(actions.setCountry(selectedCountry));
	};
	const [cardToAirlineOpen, setCardToAirlineOpen] = React.useState(false);
	const handleCardToAirlineCollapseClick = () => {
		setCardToAirlineOpen(!cardToAirlineOpen);
	};
	const countriesConfigToRender = getCountriesWithAgencyConfigForUser(auth.user?.agencies.map((x) => x.agencyId) || []);
	const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
		if (
			event.type === 'keydown' &&
			((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
		) {
			return;
		}
		setDrawerOpen((prevState) => !prevState);
	};
	const handleLogout = () => {
		CookieService.clearToken();
		CookieService.clearApiKey();
		location.reload();
	};
	useEffect(() => {
		if (auth.user?.email === 'fbuonomo@mevuelo.com') {
			audio.play();
		}
	}, []);
	const handleQuotationsClick = () => setQuotationsOpen(!quotationsOpen);
	const handleExplorerClick = () => setExplorerOpen(!explorerOpen);
	const handlePaymentClick = () => setPaymentOpen(!paymentOpen);
	const handleStatsClick = () => setStatsOpen(!statsOpen);
	const handleTransactionsListClick = () => setTransactionsListOpen(!transactionsListOpen);
	const handleQuotationOptionsClick = () => setQuotationOptionsOpen(!quotationOptionsOpen);
	const orderTypesAllowed = orderTypes.filter((x) => x.countriesAllow.indexOf(auth.country) > -1);
	const maybeOrderSimple = orderTypesAllowed.find((x) => x.id === OrderTypeEnum.SIMPLE);

	const list = () => (
		<div className={classes.list} role="presentation" onKeyDown={toggleDrawer}>
			<List>
				<ListItemButton onClick={handleMenuLink(routes.home)}>
					<ListItemIcon>
						<HomeIcon />{' '}
					</ListItemIcon>
					<ListItemText primary="Home" />
				</ListItemButton>
				{/*Quotations*/}
				<ListItemButton onClick={handleQuotationsClick}>
					<ListItemIcon>
						<RequestQuoteIcon />
					</ListItemIcon>
					<ListItemText primary="Quotations" />
					{quotationsOpen ? <ExpandLess /> : <ExpandMore />}
				</ListItemButton>
				<Collapse in={quotationsOpen} timeout="auto" unmountOnExit>
					<ListItemButton sx={{ pl: 4 }} onClick={handleMenuLink(routes.quotations.new)}>
						<ListItemIcon>
							<AddBoxIcon />{' '}
						</ListItemIcon>
						<ListItemText primary="Create" />
					</ListItemButton>
					<ListItemButton sx={{ pl: 4 }} onClick={handleMenuLink(routes.quotations.newOnlyFlights)}>
						<ListItemIcon>
							<AddBoxIcon />{' '}
						</ListItemIcon>
						<ListItemText primary="Create only flights" />
					</ListItemButton>
					<ListItemButton sx={{ pl: 4 }} onClick={handleMenuLink(routes.quotations.list)}>
						<ListItemIcon>
							<ListIcon />{' '}
						</ListItemIcon>
						<ListItemText primary="List" />
					</ListItemButton>
					{isAdminOrSuperAdmin(auth.user) && (
						<ListItemButton sx={{ pl: 4 }} onClick={handleMenuLink(routes.quotations.saleReport)}>
							<ListItemIcon>
								<ListIcon />{' '}
							</ListItemIcon>
							<ListItemText primary="Sale report" />
						</ListItemButton>
					)}
				</Collapse>
				{/*SAles Order*/}
				<>
					<ListItemButton onClick={handleMenuLink(routes.salesOrder.list)}>
						<ListItemIcon>
							<RequestQuoteIcon />
						</ListItemIcon>
						<ListItemText primary="Sales Orders" />
					</ListItemButton>
					{isAdminOrSuperAdmin(auth.user) && (
						<ListItemButton onClick={handleMenuLink(routes.providers.list)}>
							<ListItemIcon>
								<ListAltIcon />{' '}
							</ListItemIcon>
							<ListItemText primary="Providers" />
						</ListItemButton>
					)}
					<ListItemButton onClick={handleMenuLink(routes.customers.list)}>
						<ListItemIcon>
							<GroupsIcon />{' '}
						</ListItemIcon>
						<ListItemText primary="Customers" />
					</ListItemButton>
				</>
				{/*Explorer*/}
				<ListItemButton onClick={handleExplorerClick}>
					<ListItemIcon>
						<PublicIcon />
					</ListItemIcon>
					<ListItemText primary="Explorer" />
					{explorerOpen ? <ExpandLess /> : <ExpandMore />}
				</ListItemButton>
				<Collapse in={explorerOpen} timeout="auto" unmountOnExit>
					<ListItemButton sx={{ pl: 4 }} onClick={handleMenuLink(routes.packageExplorer.list)}>
						<ListItemIcon>
							<TravelExploreIcon />{' '}
						</ListItemIcon>
						<ListItemText primary="PackagesExplorer" />
					</ListItemButton>
					<ListItemButton sx={{ pl: 4 }} onClick={handleMenuLink(routes.flightExplorer.list)}>
						<ListItemIcon>
							<LocalAirportIcon />{' '}
						</ListItemIcon>
						<ListItemText primary="FlightsExplorer" />
					</ListItemButton>
				</Collapse>
				{/*Payment Links*/}
				<>
					<ListItemButton onClick={handlePaymentClick}>
						<ListItemIcon>
							<AddCardIcon />
						</ListItemIcon>
						<ListItemText primary="Payment Links" />
						{paymentOpen ? <ExpandLess /> : <ExpandMore />}
					</ListItemButton>
					<Collapse in={paymentOpen} timeout="auto" unmountOnExit>
						{auth.country === CountryCodeEnum.TN_ITAU_PY ? (
							<ListItemButton sx={{ pl: 4 }} onClick={handleOrderViajeNaranja}>
								<ListItemIcon>
									<img src={bancard} style={{ height: 30 }} />
								</ListItemIcon>
								<ListItemText primary="BanCard" />
							</ListItemButton>
						) : (
							orderTypesAllowed.map((order) => {
								return (
									<ListItemButton key={`order-${order.id}`} sx={{ pl: 4 }} onClick={() => handleOrdersClick(order.id)}>
										<ListItemIcon>{order.icon()}</ListItemIcon>
										<ListItemText primary={order.name} />
									</ListItemButton>
								);
							})
						)}

						<ListItemButton sx={{ pl: 4 }} onClick={handleWireTransferCheckClick}>
							<ListItemIcon>
								<TransferIcon />
							</ListItemIcon>{' '}
							<ListItemText primary="WireTransferCheck" />
						</ListItemButton>
						{auth.country !== CountryCodeEnum.TN_ITAU_PY && (
							<ListItemButton sx={{ pl: 4 }} onClick={handleMenuLink(routes.payment.orderCash)}>
								<ListItemIcon>
									<CashIcon />
								</ListItemIcon>{' '}
								<ListItemText primary="CashPayment" />
							</ListItemButton>
						)}
					</Collapse>
					{/*Payment Transactions*/}
					{isOperatorOrHigher(auth.user) && (
						<>
							<ListItemButton onClick={handleTransactionsListClick}>
								<ListItemIcon>
									<CreditScoreIcon />
								</ListItemIcon>
								<ListItemText primary="Payments Transactions" />
								{transactionsListOpen ? <ExpandLess /> : <ExpandMore />}
							</ListItemButton>
							<Collapse in={transactionsListOpen} timeout="auto" unmountOnExit>
								{maybeOrderSimple && (
									<>
										<ListItemButton sx={{ pl: 4 }} onClick={handleCardToAirlineCollapseClick}>
											<ListItemIcon>
												<CreditScoreIcon />
											</ListItemIcon>
											<ListItemText primary="CardToAirline" />
											{cardToAirlineOpen ? <ExpandLess /> : <ExpandMore />}
										</ListItemButton>
										<Collapse in={cardToAirlineOpen} timeout="auto" unmountOnExit>
											<ListItemButton
												key={`transaction-order-${maybeOrderSimple.id}`}
												sx={{ pl: 8 }}
												onClick={handleMenuLink(routes.transactions.list)}
											>
												<ListItemIcon>{maybeOrderSimple.icon()}</ListItemIcon>
												<ListItemText primary={maybeOrderSimple.name} />
											</ListItemButton>

											<ListItemButton sx={{ pl: 8 }} onClick={handleMenuLink(routes.authorizations.list)}>
												<ListItemIcon>
													<EventAvailableIcon />
												</ListItemIcon>
												<ListItemText primary="Authorizations" />
											</ListItemButton>
											<ListItemButton sx={{ pl: 8 }} onClick={handleMenuLink(routes.crosses.list)}>
												<ListItemIcon>
													<ImportExportIcon />
												</ListItemIcon>
												<ListItemText primary="Crosses" />
											</ListItemButton>
										</Collapse>
									</>
								)}
								{isAdminOrSuperAdmin(auth.user) && (
									<>
										{auth.country === CountryCodeEnum.TN_ITAU_PY ? (
											<>
												<ListItemButton sx={{ pl: 4 }} onClick={handleListViajeNaranjaBancardTransactionList}>
													<ListItemIcon>
														<img src={bancard} style={{ height: 30 }} />
													</ListItemIcon>
													<ListItemText primary="BanCard" />
												</ListItemButton>
												<ListItemButton sx={{ pl: 4 }} onClick={handleListViajeNaranjaPointTransactionList}>
													<ListItemIcon>
														<img src={itauPY} style={{ height: 30 }} />
													</ListItemIcon>
													<ListItemText primary="Itau" />
												</ListItemButton>
											</>
										) : (
											orderTypesAllowed.map(
												(order) =>
													order.id !== OrderTypeEnum.SIMPLE && (
														<ListItemButton
															key={`transaction-order-${order.id}`}
															sx={{ pl: 4 }}
															onClick={() => handleTransactionsClick(order.id)}
														>
															<ListItemIcon>{order.icon()}</ListItemIcon>
															<ListItemText primary={order.name} />
														</ListItemButton>
													),
											)
										)}
										{auth.country !== CountryCodeEnum.TN_ITAU_PY && (
											<>
												<ListItemButton
													sx={{ pl: 4 }}
													onClick={handleMenuLink(routes.transactions.wireTransferTransactions)}
												>
													<ListItemIcon>
														<TransferIcon />
													</ListItemIcon>
													<ListItemText primary="WireTransferList" />
												</ListItemButton>
												<ListItemButton sx={{ pl: 4 }} onClick={handleMenuLink(routes.transactions.cashTransactions)}>
													<ListItemIcon>
														<CashIcon />
													</ListItemIcon>
													<ListItemText primary="CashPayment" />
												</ListItemButton>
											</>
										)}
									</>
								)}
							</Collapse>
						</>
					)}
				</>
				{/*Rates Statistics*/}
				<ListItemButton onClick={handleStatsClick}>
					<ListItemIcon>
						<SvgIcon>
							<StatsIcon />
						</SvgIcon>
					</ListItemIcon>
					<ListItemText primary="Rate Statistics" />
					{statsOpen ? <ExpandLess /> : <ExpandMore />}
				</ListItemButton>
				<Collapse in={statsOpen} timeout="auto" unmountOnExit>
					<ListItemButton sx={{ pl: 4 }} onClick={handleMenuLink(routes.stats.ratesList)}>
						<ListItemIcon>
							<CurrencyExchange />{' '}
						</ListItemIcon>
						<ListItemText primary="Rates" />
					</ListItemButton>
					<ListItemButton sx={{ pl: 4 }} onClick={handleMenuLink(routes.stats.package)}>
						<ListItemIcon>
							<BeachAccessIcon />{' '}
						</ListItemIcon>
						<ListItemText primary="Packages" />
					</ListItemButton>
					<ListItemButton sx={{ pl: 4 }} onClick={handleMenuLink(routes.stats.automaticRoute)}>
						<ListItemIcon>
							<AirplaneTicketIcon />{' '}
						</ListItemIcon>
						<ListItemText primary="Flights" />
					</ListItemButton>
					<ListItemButton sx={{ pl: 4 }} onClick={handleMenuLink(routes.home)}>
						<ListItemIcon>
							<HotelIcon />{' '}
						</ListItemIcon>
						<ListItemText primary="Hotels" />
					</ListItemButton>
				</Collapse>
				<ListItemButton onClick={handleMenuLink(routes.quotaData)}>
					<ListItemIcon>
						<AnnouncementIcon />{' '}
					</ListItemIcon>
					<ListItemText primary="Seller Quota Information" />
				</ListItemButton>
				{isAdminOrSuperAdmin(auth.user) && (
					<ListItemButton onClick={handleMenuLink(routes.flightIssueRule.list)}>
						<ListItemIcon>
							<ListIcon />{' '}
						</ListItemIcon>
						<ListItemText primary="Reglas de emisión" />
					</ListItemButton>
				)}
				{isAdminOrSuperAdmin(auth.user) && (
					<ListItemButton onClick={handleMenuLink(routes.activityLogList)}>
						<ListItemIcon>
							<ListAltIcon />{' '}
						</ListItemIcon>
						<ListItemText primary="Activity Logs" />
					</ListItemButton>
				)}
				{/*Config*/}
				<ListItemButton onClick={handleQuotationOptionsClick}>
					<ListItemIcon>
						<SettingsIcon />
					</ListItemIcon>
					<ListItemText primary="Quotation Default Config" />
					{quotationOptionsOpen ? <ExpandLess /> : <ExpandMore />}
				</ListItemButton>
				<Collapse in={quotationOptionsOpen} timeout="auto" unmountOnExit>
					<ListItemButton sx={{ pl: 6 }} onClick={handleMenuLink(routes.quotations.description)}>
						<ListItemIcon>
							<ContentPaste />
						</ListItemIcon>
						<ListItemText primary="Description" />
					</ListItemButton>
					<ListItemButton sx={{ pl: 6 }} onClick={handleMenuLink(routes.quotations.defaultNotIncludes)}>
						<ListItemIcon>
							<PlaylistRemove />
						</ListItemIcon>
						<ListItemText primary="Not Includes" />
					</ListItemButton>
				</Collapse>
			</List>
			<Divider />
		</div>
	);

	return (
		<div className="App">
			<AppBar position="static">
				<Toolbar className={classes.toolbar}>
					<div className={classes.toolbarLeftside}>
						<IconButton
							edge="start"
							className={classes.menuButton}
							color="inherit"
							aria-label="menu"
							onClick={toggleDrawer}
							size="large"
						>
							<MenuIcon />
						</IconButton>
						<Typography variant="h6" className={classes.title}>
							T1000
						</Typography>
						<FormControl className={classes.countrySelector}>
							<InputLabel htmlFor="country-select">País</InputLabel>
							<Select
								label="País"
								value={auth.country}
								onChange={handleCountryChange}
								inputProps={{
									name: 'country',
									id: 'country-select',
								}}
								renderValue={(selected) => {
									const selectedCountry = countriesConfigToRender.find(
										(countryConfig) => countryConfig.id === selected,
									);

									return (
										<Box display="flex" alignItems="center" bgcolor="#FFFFFF" minWidth={200} borderRadius={2}>
											<img
												src={selectedCountry?.agencyIcon}
												alt={selectedCountry?.countryName}
												className={classes.flagIcon}
											/>
											<span>{selectedCountry?.countryName}</span>
										</Box>
									);
								}}
							>
								{countriesConfigToRender.map((countryConfig) => (
									<MenuItem key={countryConfig.id} value={countryConfig.id}>
										<img src={countryConfig.agencyIcon} alt={countryConfig.countryName} className={classes.flagIcon} />
										{countryConfig.countryName}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
					<div>
						<img className={classes.logo} src={MevueloLogo} alt="Mevuelo-logo" />
						<Button color="inherit" onClick={handleLogout}>
							Logout
						</Button>
					</div>
				</Toolbar>
			</AppBar>
			{props.children}
			<Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
				{list()}
			</Drawer>
		</div>
	);
};
