import React, { useEffect, useState } from 'react';

import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Divider,
	Drawer,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from '@mui/material';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import makeStyles from '@mui/styles/makeStyles/makeStyles';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import StreetviewIcon from '@mui/icons-material/Streetview';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PublicIcon from '@mui/icons-material/Public';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';
import PersonIcon from '@mui/icons-material/Person';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import TodayIcon from '@mui/icons-material/Today';

import { SegmentEnum } from 'features/salesOrder/types';
import { asociateCustomerToSaleOrder, getCustomerById } from 'features/salesOrder/services';
import { ICountryFE } from 'features/common/types';
import { ICustomerCorpoDTO, ICustomerLeisureDTO, IPatchCustomerLeisureForm } from 'features/customers/types';
import { putCustomerLeisure } from 'features/customers/services';
import { Controller, useForm } from 'react-hook-form';
import { nanoidGenerator } from 'features/common/helpers';
import { getCountries } from 'features/common/services';
import { CustomerModal } from 'features/salesOrder/components/SaleOrderCustomerModal';

const useStyles = makeStyles((theme) => ({
	container: {
		maxWidth: 1100,
		width: '100%',
		borderRadius: theme.shape.borderRadius,
	},
	icon: {
		color: theme.palette.grey[600],
	},
	header: {
		display: 'flex',
		marginLeft: theme.spacing(3),
		marginTop: theme.spacing(4),
	},
	names: {
		color: theme.palette.grey[600],
		display: 'flex',
	},
	card: {
		marginBottom: theme.spacing(5),
		margin: theme.spacing(2),
		background: theme.palette.grey[100],
		display: 'flex',
		borderRadius: theme.shape.borderRadius,
		border: '1px solid rgba(0, 0, 0, 0.12)',
		flexDirection: 'row',
		alignItems: 'flex-start',

		width: '96%',
	},
	title: {
		color: theme.palette.grey[600],
		paddingTop: theme.spacing(1),
	},
	containerHeader: {
		justifyContent: 'space-between',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	search: {
		color: theme.palette.warning.light,
		height: '80%',
	},
	backdrop: {
		zIndex: theme.zIndex.modal + 1,
		color: theme.palette.common.white,
	},
	item: {
		display: 'flex',
		justifyContent: 'space-between',
	},
}));

interface Props {
	segmentType: SegmentEnum;
	customer: ICustomerLeisureDTO | ICustomerCorpoDTO;
	id: string;
	customerToShow: any;
}

export const Customer = ({ segmentType, customer, id, customerToShow }: Props): JSX.Element => {
	const classes = useStyles();
	const [openModal, setOpenModal] = React.useState(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [customerType, setCustomerType] = useState<ICustomerLeisureDTO | ICustomerCorpoDTO>();

	const [countries, setCountries] = useState<ICountryFE[]>([]);

	const [openDrawer, setOpenDrawer] = useState(false);

	const handleCloseDrawer = () => {
		setOpenDrawer(false);
	};

	const {
		register,
		handleSubmit,
		control,
		setValue,
		formState: { errors },
	} = useForm<IPatchCustomerLeisureForm>();

	const handleCreateCustomer = () => {
		setOpenModal(false);
		setOpenDrawer(true);
		setValue('id', nanoidGenerator());
	};

	const handleOpen = () => {
		setOpenModal(true);
	};
	const handleClose = () => {
		setOpenModal(false);
	};

	const handleOptionSelection = async (customerId: string) => {
		if (customerId) {
			try {
				setLoading(true);
				const response = (await getCustomerById(customerId)).data;
				setCustomerType(response);
			} catch (error) {
				alert('Ocurrió un error al completar los datos');
			} finally {
				setLoading(false);
			}
		}
	};

	const onSubmitFormCreateCustomerLeisure = async (formData: IPatchCustomerLeisureForm) => {
		try {
			const countryFound = countries.find((item) => item.code === formData.country);

			if (countryFound) {
				const request: Partial<ICustomerLeisureDTO> = {
					id: formData.id,
					name: formData.name,
					lastName: formData.lastName,
					country: {
						code: countryFound.code,
						name: countryFound.name,
					},
					stateName: formData.stateName,
					cityName: formData.cityName,
					street: formData.street,
					postalCode: formData.postalCode,
					email: formData.email,
				};
				if (formData.taxIdentificationNumber) {
					request.taxIdentificationNumber = formData.taxIdentificationNumber;
				}
				if (formData.phone) {
					request.phone = formData.phone;
				}
				await putCustomerLeisure(request);
				if (request.id) {
					setCustomerType(request as ICustomerLeisureDTO);
					await asociateCustomerToSaleOrder(id, request.id);
					alert('Cliente guardado con éxito');
					handleCloseDrawer();
				} else {
					alert('ID del cliente es indefinido');
				}
			} else {
				alert('Error con el pais seleccionado');
			}
		} catch (_e) {
			alert('Ocurrió un error inesperado, vuelve a intentarlo');
		}
	};

	useEffect(() => {
		const fetchCountries = async () => {
			try {
				const { data } = await getCountries();
				setCountries(data);
				if (customerToShow) {
					setValue('id', customerToShow.id);
					setValue('name', customerToShow.name);
					setValue('lastName', customerToShow.lastName);
					setValue('phone', customerToShow.phone);
					setValue('email', customerToShow.email);

					const selectedCountry = data.find((country) => country.plCitizenCode === customerToShow.nationality.code);

					const plCitizenCode = selectedCountry ? selectedCountry.code : 'UY';

					setValue('country', plCitizenCode);
				}
			} catch (_e) {
				console.error(_e);
			}
		};

		fetchCountries();
	}, [customerToShow, setValue]);

	useEffect(() => {
		setCustomerType(customer);
	}, [customer]);

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Grid className={classes.container}>
				<Grid className={classes.containerHeader}>
					<Grid className={classes.header}>
						<FaceRetouchingNaturalIcon className={classes.icon} />
						<Typography variant="h6" pl={2} className={classes.names}>
							Cliente
						</Typography>
					</Grid>
					<Grid container spacing={2} className={classes.card}>
						<Grid item xs={12} sm={2}>
							<Grid item xs={12} sm={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
								<Typography className={classes.title}>Buscar Cliente</Typography>
								<IconButton className={classes.search} onClick={handleOpen}>
									<PersonSearchIcon />
								</IconButton>
							</Grid>
							{openModal && (
								<CustomerModal
									open={openModal}
									handleClose={handleClose}
									segmentType={segmentType}
									handleOptionSelection={handleOptionSelection}
									saleOrderId={id}
									handleCreateCustomer={handleCreateCustomer}
								/>
							)}
							{openDrawer && (
								<Drawer anchor="right" open={openDrawer} onClose={handleCloseDrawer}>
									<Box sx={{ padding: 3, maxWidth: 600 }} display="flex" flexDirection="column" gap={3}>
										<Typography component="h1" variant="h5">
											Nuevo cliente
										</Typography>
										<form onSubmit={handleSubmit(onSubmitFormCreateCustomerLeisure)}>
											<Grid container spacing={1}>
												<Grid item xs={12}>
													<input type="hidden" {...register('id')} />
													<TextField
														variant="outlined"
														required
														fullWidth
														label="Nombre"
														size="small"
														{...register('name', { required: true })}
														error={!!errors.name}
														helperText={errors.name && 'Campo requerido'}
													/>
												</Grid>
												<Grid item xs={12}>
													<TextField
														variant="outlined"
														required
														fullWidth
														label="Apellido"
														size="small"
														{...register('lastName', { required: true })}
														error={!!errors.lastName}
														helperText={errors.lastName && 'Campo requerido'}
													/>
												</Grid>
												<Grid item xs={12}>
													<FormControl size="small" fullWidth>
														<InputLabel id="country">Pais</InputLabel>
														<Controller
															render={({ field }) => (
																<Select labelId="country" label="Pais" {...field}>
																	{countries.map((item) => (
																		<MenuItem key={item.code} value={item.code}>
																			{item.name}
																		</MenuItem>
																	))}
																</Select>
															)}
															defaultValue="UY"
															name="country"
															control={control}
														/>
													</FormControl>
												</Grid>
												<Grid item xs={12}>
													<TextField
														variant="outlined"
														required
														fullWidth
														label="Estado"
														size="small"
														{...register('stateName', { required: true })}
														error={!!errors.stateName}
														helperText={errors.stateName && 'Campo requerido'}
													/>
												</Grid>
												<Grid item xs={12}>
													<TextField
														variant="outlined"
														required
														fullWidth
														label="Ciudad"
														size="small"
														{...register('cityName', { required: true })}
														error={!!errors.cityName}
														helperText={errors.cityName && 'Campo requerido'}
													/>
												</Grid>
												<Grid item xs={12}>
													<TextField
														variant="outlined"
														required
														fullWidth
														label="Direccion"
														size="small"
														{...register('street', { required: true })}
														error={!!errors.street}
														helperText={errors.street && 'Campo requerido'}
													/>
												</Grid>
												<Grid item xs={12}>
													<TextField
														variant="outlined"
														required
														fullWidth
														label="Codigo Postal"
														size="small"
														{...register('postalCode', { required: true })}
														error={!!errors.postalCode}
														helperText={errors.postalCode && 'Campo requerido'}
													/>
												</Grid>
												<Grid item xs={12}>
													<TextField
														variant="outlined"
														fullWidth
														label="Tax identification number"
														size="small"
														{...register('taxIdentificationNumber')}
														error={!!errors.taxIdentificationNumber}
														helperText={errors.taxIdentificationNumber && 'Campo requerido'}
													/>
												</Grid>
												<Grid item xs={12}>
													<TextField
														variant="outlined"
														fullWidth
														label="Telefono"
														size="small"
														{...register('phone')}
														error={!!errors.phone}
														helperText={errors.phone && 'Campo requerido'}
													/>
												</Grid>
												<Grid item xs={12}>
													<TextField
														variant="outlined"
														required
														fullWidth
														label="Email"
														size="small"
														{...register('email', { required: true })}
														error={!!errors.email}
														helperText={errors.email && 'Campo requerido'}
													/>
												</Grid>
											</Grid>
											<Box display="flex" justifyContent={'center'} m={2}>
												<Button variant="contained" color="success" type="submit">
													Asociar cliente
												</Button>
											</Box>
										</form>
									</Box>
								</Drawer>
							)}
						</Grid>
						<Divider orientation="vertical" flexItem sx={{ my: 2, mx: 1, mb: 1 }} />
						{customerType ? (
							<>
								<Grid item xs={12} sm={4.7} sx={{ pb: 2, pr: 8 }}>
									<Grid item xs={12} sm={12} className={classes.item}>
										<Typography className={classes.title}>
											<PersonIcon sx={{ fontSize: 15, pr: 1 }} />
											Nombre:
										</Typography>
										<Typography pt={1}>{customerType?.name} </Typography>
									</Grid>
									{segmentType === SegmentEnum.LEISURE && (
										<Grid item xs={12} sm={12} className={classes.item}>
											<Typography className={classes.title}>
												<PeopleAltIcon sx={{ fontSize: 15, pr: 1 }} />
												Apellido:
											</Typography>
											<Typography pt={1}>{(customerType as ICustomerLeisureDTO)?.lastName ?? ''}</Typography>
										</Grid>
									)}
									<Grid item xs={12} sm={12} className={classes.item}>
										<Typography className={classes.title}>
											<PublicIcon sx={{ fontSize: 15, pr: 1 }} />
											Pais:
										</Typography>
										<Typography pt={1}>{customerType?.country.name}</Typography>
									</Grid>
									<Grid item xs={12} sm={12} className={classes.item}>
										<Typography className={classes.title}>
											<MarkunreadMailboxIcon sx={{ fontSize: 15, pr: 1 }} />
											Codigo Postal:
										</Typography>
										<Typography pt={1}>{customerType?.postalCode}</Typography>
									</Grid>
									<Grid item xs={12} sm={12} className={classes.item}>
										<Typography className={classes.title}>
											<ApartmentIcon sx={{ fontSize: 15, pr: 1 }} />
											Estado:
										</Typography>
										<Typography pt={1}>{customerType?.stateName}</Typography>
									</Grid>
									{segmentType === SegmentEnum.CORPO && (
										<>
											<Grid item xs={12} sm={12} className={classes.item}>
												<Typography className={classes.title}>
													<CreditScoreIcon sx={{ fontSize: 15, pr: 1 }} />
													Crédito:
												</Typography>
												{(customerType as ICustomerCorpoDTO)?.credit !== undefined &&
												(customerType as ICustomerCorpoDTO)?.credit !== null ? (
													<Typography pt={1}>USD {(customerType as ICustomerCorpoDTO)?.credit}</Typography>
												) : (
													''
												)}
											</Grid>
											<Grid item xs={12} sm={12} className={classes.item}>
												<Typography className={classes.title}>
													<TodayIcon sx={{ fontSize: 15, pr: 1 }} />
													Dias de crédito:
												</Typography>
												<Typography pt={1}>{(customerType as ICustomerCorpoDTO)?.daysOfCredit ?? ''}</Typography>
											</Grid>
										</>
									)}
								</Grid>
								<Grid item xs={12} sm={5.1} sx={{ pb: 2, pr: 8 }}>
									<Grid item xs={12} sm={12} className={classes.item}>
										<Typography className={classes.title}>
											<LocationCityIcon sx={{ fontSize: 15, pr: 1 }} />
											Ciudad:
										</Typography>
										<Typography pt={1}>{customerType?.cityName}</Typography>
									</Grid>
									<Grid item xs={12} sm={12} className={classes.item}>
										<Typography className={classes.title}>
											<StreetviewIcon sx={{ fontSize: 15, pr: 1 }} />
											Dirección:
										</Typography>
										<Typography pt={1}>{customerType?.street}</Typography>
									</Grid>
									<Grid item xs={12} sm={12} className={classes.item}>
										<Typography className={classes.title}>
											<BusinessCenterIcon sx={{ fontSize: 15, pr: 1 }} />
											Tax Identification Number:
										</Typography>
										<Typography pt={1}>{customerType?.taxIdentificationNumber}</Typography>
									</Grid>
									<Grid item xs={12} sm={12} className={classes.item}>
										<Typography className={classes.title}>
											<LocalPhoneIcon sx={{ fontSize: 15, pr: 1 }} />
											Teléfono:
										</Typography>
										<Typography pt={1}>{customerType?.phone}</Typography>
									</Grid>
									<Grid item xs={12} sm={12} className={classes.item}>
										<Typography className={classes.title}>
											<AlternateEmailIcon sx={{ fontSize: 15, pr: 1 }} />
											Email:
										</Typography>
										<Typography pt={1}>{customerType?.email}</Typography>
									</Grid>
								</Grid>
							</>
						) : (
							<Grid item sx={{ pb: 2, pr: 8, display: 'flex', mt: 1 }}>
								<FaceRetouchingNaturalIcon />
								<Typography variant="body1" sx={{ pl: 1 }}>
									Aún no tienes un cliente asociado.
								</Typography>
							</Grid>
						)}
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
