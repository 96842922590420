import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, TableCell, Button, TableRow, Paper, styled, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles/makeStyles';
import PlaceIcon from '@mui/icons-material/Place';
import VerifiedIcon from '@mui/icons-material/Verified';
import PersonIcon from '@mui/icons-material/Person';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import FlightIcon from '@mui/icons-material/Flight';
import HotelIcon from '@mui/icons-material/Hotel';
import SecurityIcon from '@mui/icons-material/Security';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import SignpostIcon from '@mui/icons-material/Signpost';
import CarRentalIcon from '@mui/icons-material/CarRental';
import TrainIcon from '@mui/icons-material/Train';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import LuggageIcon from '@mui/icons-material/Luggage';
import GroupsIcon from '@mui/icons-material/Groups';
import HouseboatIcon from '@mui/icons-material/Houseboat';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EventIcon from '@mui/icons-material/Event';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import { CountryCodeEnum } from '../../../common/types';
import { TypeServiceEnum } from '../../types';
import { headerCells } from '../SaleOrderList/constants';
import routes from 'constants/routes';
import { format } from 'date-fns';
import { balanceStyle } from '../SaleOrderList/styles';

const useStyles = makeStyles((theme) => ({
	serviceIcon: {
		innerHeight: '1px',
		color: theme.palette.warning.main,
		paddingRight: 5,
	},
	tableCell: {
		padding: theme.spacing(1),
		boxSizing: 'border-box',
		width: '100%',
	},
	grid: {
		width: '100%',
	},
}));

const DetailItem = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#FFFFFF',
	padding: theme.spacing(1),
	textAlign: 'center',
	minHeight: '52px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-around',
	boxSizing: 'border-box',
	fontSize: theme.typography.body2.fontSize,
}));

const calculateMarginPercentage = (grossProfit?: number, saleAmount?: number): string => {
	if (grossProfit === undefined || saleAmount === undefined || saleAmount === 0) {
		return '-';
	}
	const marginPercentage = (grossProfit / saleAmount) * 100;
	return `${marginPercentage.toFixed(2)}%`;
};

export interface ISalesOrderDetails {
	rowData: {
		id: string;
		dealId: string;
		quotationId?: string;
		created: string;
		currency: string;
		travelDepartureDate?: string;
		travelReturnDate?: string;
		saleAmount?: number;
		totalCost?: number;
		grossProfit?: number;
		chargedAmount?: number;
		pendingPayAmount?: number;
		destinations?: string[];
		servicesTypes?: TypeServiceEnum;
		mainPassengerCompleteName?: string;
		customerCompleteName?: string;
		market: CountryCodeEnum;
		updated?: string;
		createdBy: string;
		updatedBy?: string;
	};
}

const formatDate = (value: string) => {
	const arr = value.split('-');
	return `${arr[2]}/${arr[1]}/${arr[0]}`;
};

export const transformValue = (key: string, value: any | string, row: any | string): React.ReactNode => {
	const classes = useStyles();
	switch (key) {
		case 'destinations':
			if (typeof value === 'string' && value.length > 0) {
				const destinationsArray = value.split(', ');
				const transformedDestinations = destinationsArray.map((destination: string) => {
					if (destination.length >= 3) {
						return destination.substring(3);
					} else {
						return destination;
					}
				});
				return transformedDestinations.join(', ');
			} else {
				return row.destinations ? row.destinations : '-';
			}
		case 'departureDate':
			return row && row.travelDepartureDate ? format(new Date(row.travelDepartureDate), 'dd/MM/yyyy') : '-';
		case 'returnDate':
			return row && row.travelReturnDate ? format(new Date(row.travelReturnDate), 'dd/MM/yyyy') : '-';
		case 'created':
			return row && row.created ? format(new Date(row.created), 'dd/MM/yyyy') : '-';
		case 'updated':
			return row && row.updated ? format(new Date(row.created), 'dd/MM/yyyy') : '-';
		case 'updatedBy':
			return row && row.updatedBy ? row.updatedBy : '-';
		case 'hiredServices':
			return (
				<>
					{row.servicesTypes && row.servicesTypes.length > 0
						? row.servicesTypes.map((service: any, index: any) => (
								<span key={index} className={classes.serviceIcon}>
									{service === TypeServiceEnum.Flight ? (
										<FlightIcon />
									) : service === TypeServiceEnum.Accommodation ? (
										<HotelIcon />
									) : service === TypeServiceEnum.Insurance ? (
										<SecurityIcon />
									) : service === TypeServiceEnum.Transfer ? (
										<AirportShuttleIcon />
									) : service === TypeServiceEnum.Circuit ? (
										<SignpostIcon />
									) : service === TypeServiceEnum.CarRental ? (
										<CarRentalIcon />
									) : service === TypeServiceEnum.Train ? (
										<TrainIcon />
									) : service === TypeServiceEnum.Bus ? (
										<DirectionsBusIcon />
									) : service === TypeServiceEnum.Activity ? (
										<LocalActivityIcon />
									) : service === TypeServiceEnum.FlightAddOn ? (
										<LuggageIcon />
									) : service === TypeServiceEnum.ThirdPartyPackage ? (
										<GroupsIcon />
									) : service === TypeServiceEnum.Ferry ? (
										<HouseboatIcon />
									) : service === TypeServiceEnum.Cruise ? (
										<DirectionsBoatIcon />
									) : service === TypeServiceEnum.Other ? (
										<MoreHorizIcon />
									) : (
										''
									)}
								</span>
						  ))
						: '-'}
				</>
			);
		case 'customer':
			return row.customer ? row.customer : '-';
		case 'mainPassenger':
			return row.mainPassengerCompleteName ? row.mainPassengerCompleteName : '-';
		case 'customerCompleteName':
			return row.customerCompleteName ? row.customerCompleteName : '-';
		case 'market':
			return row.market ? row.market : '-';
		case 'lastEdition':
			return row && row.updated ? format(new Date(row.updated), 'dd/MM/yyyy') : '-';
		case 'seller':
			return row.createdBy ? row.createdBy : '-';
		default:
			return value ? value : '-';
	}
};

export function ExpandedRowDetailsSalesOrderList({ rowData }: ISalesOrderDetails): JSX.Element {
	const classes = useStyles();
	const history = useHistory();
	const handleVerMasClick = (id: any) => () => {
		history.push(routes.salesOrder.edit.replace(':id', id));
	};
	return (
		<TableRow>
			<TableCell colSpan={headerCells.length + 2} className={classes.tableCell}>
				<Grid container spacing={1} className={classes.grid}>
					<Grid item xs={4}>
						<DetailItem>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<PlaceIcon sx={{ fontSize: 20, color: 'Grey' }} />
								<Typography variant="body2" fontWeight={600}>
									DESTINO/S:
								</Typography>
							</div>
							<Typography variant="body2" style={{ marginLeft: 'auto' }}>
								{' '}
								{transformValue('destinations', rowData.destinations, rowData)}
							</Typography>
						</DetailItem>
					</Grid>
					<Grid item xs={4}>
						<DetailItem>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<EventIcon sx={{ fontSize: 20, color: 'Grey' }} />
								<Typography variant="body2" fontWeight={600}>
									VIAJE:
								</Typography>
							</div>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<FlightTakeoffIcon sx={{ fontSize: 20, color: 'Grey' }} />
								<Typography variant="body2" style={{ marginLeft: 'auto' }}>
									{rowData.travelDepartureDate ? formatDate(rowData.travelDepartureDate) : '-'}
								</Typography>
							</div>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<FlightLandIcon sx={{ fontSize: 20, color: 'Grey' }} />
								<Typography variant="body2" style={{ marginLeft: 'auto' }}>
									{rowData.travelReturnDate ? formatDate(rowData.travelReturnDate) : '-'}
								</Typography>
							</div>
						</DetailItem>
					</Grid>
					<Grid item xs={4}>
						<DetailItem>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<HomeRepairServiceIcon sx={{ fontSize: 20, color: 'Grey' }} />
								<Typography variant="body2" fontWeight={600}>
									SERVICIO/S:
								</Typography>
							</div>
							{transformValue('hiredServices', rowData.servicesTypes, rowData)}
						</DetailItem>
					</Grid>
					<Grid item xs={3}>
						<DetailItem>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<PersonIcon sx={{ fontSize: 20, color: 'Grey' }} />
								<Typography variant="body2" fontWeight={600}>
									MAIN PAX:
								</Typography>
							</div>
							<Typography variant="body2" style={{ marginLeft: 'auto' }}>
								{transformValue('mainPassenger', rowData.mainPassengerCompleteName, rowData)}
							</Typography>
						</DetailItem>
					</Grid>
					<Grid item xs={2}>
						<DetailItem>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<PeopleAltIcon sx={{ fontSize: 20, color: 'Grey' }} />
								<Typography variant="body2" fontWeight={600}>
									Q PAX:
								</Typography>
							</div>
							{/* FIX ME: falta agregar el count de pasajeros */}
							<Typography variant="body2" style={{ marginLeft: 'auto' }}>
								pend
							</Typography>
						</DetailItem>
					</Grid>
					<Grid item xs={3}>
						<DetailItem>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<VerifiedIcon sx={{ fontSize: 20, color: 'Grey' }} />
								<Typography variant="body2" fontWeight={600}>
									CLIENTE:
								</Typography>
							</div>
							<Typography variant="body2" style={{ marginLeft: 'auto' }}>
								{transformValue('customerCompleteName', rowData.customerCompleteName, rowData)}
							</Typography>
						</DetailItem>
					</Grid>
					<Grid item xs={2}>
						<DetailItem>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<AttachMoneyIcon sx={{ fontSize: 20, color: 'Grey' }} />
								<Typography variant="body2" fontWeight={600}>
									VENTA:
								</Typography>
							</div>
							<Typography variant="body2" style={{ marginLeft: 'auto' }}>
								USD {transformValue('default', rowData.saleAmount, rowData)}
							</Typography>
						</DetailItem>
					</Grid>
					<Grid item xs={2}>
						<DetailItem>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<PriceCheckIcon sx={{ fontSize: 20, color: 'Grey' }} />
								<Typography variant="body2" fontWeight={600}>
									MB:
								</Typography>
							</div>
							<Typography variant="body2" style={{ marginLeft: 'auto' }}>
								USD {transformValue('default', rowData.grossProfit, rowData)} {'('}
								{calculateMarginPercentage(rowData.grossProfit, rowData.saleAmount)}
								{')'}
							</Typography>
						</DetailItem>
					</Grid>
					<Grid item xs={3}>
						<DetailItem>
							<Typography variant="body2" fontWeight={600}>
								CREADO:
							</Typography>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<SupportAgentIcon sx={{ fontSize: 20, color: 'Grey' }} />
								<Typography variant="body2" style={{ marginLeft: 'auto' }}>
									{transformValue('seller', rowData.createdBy, rowData)}
								</Typography>
							</div>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<EventIcon sx={{ fontSize: 20, color: 'Grey' }} />
								<Typography variant="body2" style={{ marginLeft: 'auto' }}>
									{transformValue('created', rowData.created, rowData)}
								</Typography>
							</div>
						</DetailItem>
					</Grid>
					<Grid item xs={3}>
						<DetailItem>
							<Typography variant="body2" fontWeight={600}>
								ACTUALIZADO:
							</Typography>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<SupportAgentIcon sx={{ fontSize: 20, color: 'Grey' }} />
								<Typography variant="body2" style={{ marginLeft: 'auto' }}>
									{transformValue('updatedBy', rowData.updatedBy, rowData)}
								</Typography>
							</div>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<EventIcon sx={{ fontSize: 20, color: 'Grey' }} />
								<Typography variant="body2" style={{ marginLeft: 'auto' }}>
									{transformValue('updated', rowData.updated, rowData)}
								</Typography>
							</div>
						</DetailItem>
					</Grid>
					<Grid item xs={2}>
						<DetailItem>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<WarningAmberIcon sx={{ fontSize: 20, color: 'Grey' }} />
								<Typography variant="body2" fontWeight={600}>
									SALDO:
								</Typography>
							</div>
							<Typography variant="body2" style={balanceStyle(rowData.pendingPayAmount, rowData.travelDepartureDate)}>
								USD {transformValue('default', rowData.pendingPayAmount, rowData)}
							</Typography>
						</DetailItem>
					</Grid>
					<Grid item xs={4}>
						<DetailItem>
							<Button variant="contained" fullWidth onClick={handleVerMasClick(rowData.id)}>
								VER MAS | EDITAR
							</Button>
						</DetailItem>
					</Grid>
				</Grid>
			</TableCell>
		</TableRow>
	);
}
