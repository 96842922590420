import React, { useState, useCallback, useEffect } from 'react';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import {
	Autocomplete,
	Box,
	Button,
	FormHelperText,
	Grid,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
	Typography,
} from '@mui/material';

import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import LockIcon from '@mui/icons-material/Lock';

import airportsOptions from './airports_options.json';

import {
	BaggageEnum,
	ICodeName,
	IFlightService,
	IFlightServiceLeg,
	ISelectOption,
	IServiceCostVariationLogs,
	ServiceUnitEnum,
	TypeServiceEnum,
	typeUnit,
} from 'features/salesOrder/types';
import { ControlledAutoComplete } from 'features/common/components/ControlledAutoComplete';
import { ControlledTextField } from 'features/common/components/ControlledTextField';
import { checkShowErrorMessage, nanoidGenerator } from 'features/common/helpers';
import { ControlledSelect } from 'features/common/components/ControlledSelect';
// import { ControlledCheckBox } from 'features/common/components/ControlledCheckBox';
import {
	getCostVariationLogsByService,
	getOIDIssues,
	parseGDSAirRawDataSaleOrder,
	putFlightLeg,
	putServiceForm,
} from 'features/salesOrder/services';
import { IValidatingCarrier } from 'features/transactions/types';

import { FlightsTable } from './FlightsTable';
import { AutocompleteProviders } from 'features/common/components/AutocompleteProviders';
import {
	// baggageOptions,
	gdsOptions,
	noAplicaGenerico,
} from 'features/salesOrder/constants';
import { ControlledDateTimePicker } from 'features/common/components/ControlledDateTimePicker';
import { useStyles } from 'features/salesOrder/components/SalesOrderServiceForm/styles';
import {
	calcularPrecioFinal,
	calculatMarkupPercentaje,
	excludeTypeUnits,
	filterServiceCostVariationLogs,
	returnPaxName,
} from 'features/salesOrder/components/SalesOrderServiceForm/helpers';
import { ServiceCostVariationLogs } from 'features/salesOrder/components/SalesOrderServiceForm/components/ServiceCostVariationLogs';
import { useParams } from 'react-router-dom';
import { ShowAlertState } from 'features/common/types';
import { ControlledCheckBox } from '../../../../../../features/common/components/ControlledCheckBox';

interface OpcionAirport {
	label: string;
	id: string;
}

interface Props {
	index: number;
	passengers: { keyId: string; name: string; lastName: string }[];
	service: IFlightService;
	currencyesList: ICodeName[];
	validatingCarriers: IValidatingCarrier[];
	onUpdateService: () => void;
	onSetAlert: (values: ShowAlertState) => void;
	onSetLoading: (value: boolean) => void;
	isExistingService?: boolean;
}
const iconMapping = {
	Persona: <PersonIcon />,
	Grupo: <GroupIcon />,
};

interface DestinationDetail {
	airportCode: string | null;
	date: string; // Puede ser más preciso con un tipo Date si lo prefieres
	hour: string;
}
export interface FlightLeg {
	flightNumber: string;
	airlineCode: string;
	departure: DestinationDetail;
	arrival: DestinationDetail;
}

const initialFlightLeg = {
	flightNumber: '',
	airlineCode: 'noselection',
	departure: { airportCode: 'noselection', date: '', hour: '' },
	arrival: { airportCode: 'noselection', date: '', hour: '' },
};

const isArrivalBeforeDeparture = (
	departureDate: string,
	departureTime: string,
	arrivalDate: string,
	arrivalTime: string,
): boolean => {
	// Combinar las fechas y horas en un solo string
	const departureDateTimeString = `${departureDate}T${departureTime}:00`;
	const arrivalDateTimeString = `${arrivalDate}T${arrivalTime}:00`;

	// Crear objetos Date a partir de los strings combinados
	const departureDateTime = new Date(departureDateTimeString);
	const arrivalDateTime = new Date(arrivalDateTimeString);

	// Comparar las dos fechas
	return departureDateTime > arrivalDateTime;
};

export const FlightService = ({
	index,
	passengers,
	service,
	currencyesList,
	validatingCarriers,
	onUpdateService,
	onSetAlert,
	onSetLoading,
	isExistingService,
}: Props): JSX.Element => {
	const classes: any = useStyles();
	const methods = useForm();
	// 	{
	// 	defaultValues: {
	// 		//@ts-ignore
	// 		passengersTickets: [],
	// 	},
	// }
	const {
		control,
		formState: { errors },
		setValue,
		getValues,
		reset,
		resetField,
		watch,
		register,
	} = methods;

	// @ts-ignore
	const {
		fields: paxTickets,
		append,
		remove,
	} = useFieldArray({
		control,
		// @ts-ignore
		name: 'passengersTickets',
	});

	const [flightLeg, setFlightLeg] = useState<FlightLeg>({ ...initialFlightLeg });

	const handleFlightNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFlightLeg({ ...flightLeg, flightNumber: event.target.value });
	};

	const handleAirlineCodeChange = (event: SelectChangeEvent<string>) => {
		setFlightLeg({ ...flightLeg, airlineCode: event.target.value as string });
	};
	const handleDepartureAirportCodeChange = (
		_event: React.SyntheticEvent<Element, Event>,
		newValue: OpcionAirport | null,
	) => {
		// setFlightLeg({ ...flightLeg, airlineCode: event.target.value as string });
		if (newValue) {
			setFlightLeg({
				...flightLeg,
				departure: { ...flightLeg.departure, airportCode: newValue.id },
			});
		}
	};

	// const handleDepartureAirportCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
	// 	setFlightLeg({
	// 		...flightLeg,
	// 		departure: { ...flightLeg.departure, airportCode: event.target.value },
	// 	});
	// };

	const handleDepartureDateTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const [date, hour] = event.target.value.split('T');
		setFlightLeg({
			...flightLeg,
			departure: { ...flightLeg.departure, date, hour },
		});
	};

	// const handleArrivalAirportCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
	// 	setFlightLeg({
	// 		...flightLeg,
	// 		arrival: { ...flightLeg.arrival, airportCode: event.target.value },
	// 	});
	// };

	// const handleArrivalAirportCodeChange = (event: SelectChangeEvent<string>) => {
	// 	// setFlightLeg({ ...flightLeg, airlineCode: event.target.value as string });
	// 	setFlightLeg({
	// 		...flightLeg,
	// 		arrival: { ...flightLeg.arrival, airportCode: event.target.value as string },
	// 	});
	// };

	const handleArrivalAirportCodeChange = (
		_event: React.SyntheticEvent<Element, Event>,
		newValue: OpcionAirport | null,
	) => {
		// setFlightLeg({ ...flightLeg, airlineCode: event.target.value as string });
		if (newValue) {
			setFlightLeg({
				...flightLeg,
				arrival: { ...flightLeg.arrival, airportCode: newValue.id },
			});
		}
	};

	const handleArrivalDateTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const [date, hour] = event.target.value.split('T');
		setFlightLeg({
			...flightLeg,
			arrival: { ...flightLeg.arrival, date, hour },
		});
	};

	const [serviceCostVariationLogs, setServiceCostVariationLogs] = useState<IServiceCostVariationLogs[]>([]);

	const { id: saleOrderId } = useParams<{ id: string }>();
	const [oidIssuesList, setOidIssuesList] = useState<ISelectOption[]>([{ ...noAplicaGenerico }]);
	const [gdsRawData, setGdsRawData] = useState('');
	const [flightLegs, setFlightLegs] = useState<IFlightServiceLeg[]>([]);
	const idService = service.keyId;

	const filteredArray = filterServiceCostVariationLogs(serviceCostVariationLogs, idService);

	const unit = watch('unit' as `${string}.${string | number}`);
	const withTst = watch('withTst');
	const passengersSelected = watch('passengerIds' as `${string}.${string | number}`) || [];

	useEffect(() => {
		const cost = getValues('cost' as `${string}.${string | number}`) || 0;
		const saleAmount = getValues('saleAmount' as `${string}.${string | number}`) || 0;
		calculateTotalCost(cost, saleAmount);
	}, [unit, passengersSelected]);

	useEffect(() => {
		const diff = paxTickets.length - passengersSelected.length;
		console.log('fede1', { paxTickets, diff });
		if (diff < 0) {
			for (let i = 0; i < passengersSelected.length; i++) {
				if (Boolean(paxTickets[i]) === false) {
					append({ passengerId: passengersSelected[i], ticket: '-' });
				}
			}
		} else if (diff > 0) {
			for (let i = 0; i < paxTickets.length; i++) {
				console.log('fede2', { passengersSelected, diff });
				// @ts-ignore
				if (passengersSelected.includes(paxTickets[i].passengerId) === false) {
					remove(i);
				}
			}
		}
	}, [paxTickets, passengersSelected, append, remove]);

	const handleAddLeg = async () => {
		if (flightLeg.airlineCode === 'noselection') {
			alert('Tiene que elegir una aerolinea');
			return false;
		}

		if (flightLeg.departure.airportCode === 'noselection') {
			alert('Tiene que elegir un aeropuerto de salida');
			return false;
		}

		if (flightLeg.arrival.airportCode === 'noselection') {
			alert('Tiene que elegir un aeropuerto de llegada');
			return false;
		}

		if (flightLeg.flightNumber === '') {
			alert('Tienes que ingresar un numero de vuelo');
			return false;
		}

		if (
			isArrivalBeforeDeparture(
				flightLeg.departure.date,
				flightLeg.departure.hour,
				flightLeg.arrival.date,
				flightLeg.arrival.hour,
			)
		) {
			alert('La fecha de llegada no puede ser anterior a la fecha de salida');
			return false;
		}

		const data = { ...flightLeg, keyId: nanoidGenerator() };
		try {
			await putFlightLeg(saleOrderId, idService, data);
			onSetAlert({
				show: true,
				severity: 'success',
				message: 'Pierna Agregada',
			});
			setFlightLeg({
				...flightLeg,
				flightNumber: '',
				departure: { ...flightLeg.departure, airportCode: null },
				arrival: { ...flightLeg.arrival, airportCode: null },
			});
		} catch (error) {
			// @ts-ignore
			alert(error.response.data.message);
		} finally {
			setTimeout(() => {
				onSetLoading(false);
				onUpdateService();
			}, 500);
		}
	};

	const handleGdsRawDataChange = (inputValue: string) => {
		setGdsRawData(inputValue);
	};

	const handleAddTST = async () => {
		try {
			onSetLoading(true);
			const response = await parseGDSAirRawDataSaleOrder(gdsRawData);
			const result = response.data;

			if (Array.isArray(result) && result?.length > 0) {
				setFlightLegs(result);

				onSetAlert({
					show: true,
					severity: 'success',
					message: 'TST agregado',
				});
			}
		} catch (error) {
			// @ts-ignore
			alert(error.response.data.message);
		} finally {
			onSetLoading(false);
		}
	};

	const handleCostChange = (newCost: number) => {
		// @ts-ignore
		setValue('cost', newCost);
		const saleAmount = getValues('saleAmount' as `${string}.${string | number}`) || 0;
		const newMarkup = calculatMarkupPercentaje(newCost, saleAmount) as string;
		// @ts-ignore
		setValue('markupPercentage', newMarkup);
		calculateTotalCost(newCost, saleAmount);
	};

	const handleMarkupPercentageChange = (newValue: number) => {
		// @ts-ignore
		setValue('markupPercentage', newValue);
		const cost = getValues('cost' as `${string}.${string | number}`) || 0;
		const newSaleAmount = calcularPrecioFinal(cost, newValue) as string;
		// @ts-ignore
		setValue('saleAmount', newSaleAmount);
		calculateTotalCost(cost, newSaleAmount);
	};

	const handleSaleAmountChange = (newValue: number) => {
		// @ts-ignore
		setValue('saleAmount', newValue);
		const cost = getValues('cost' as `${string}.${string | number}`) || 0;

		if (newValue === 0) {
			setValue('markupPercentage', 0);
		} else {
			const newMarkup = calculatMarkupPercentaje(cost, newValue) as string;
			// @ts-ignore
			setValue('markupPercentage', newMarkup);
		}
		calculateTotalCost(cost, newValue);
	};

	const calculateTotalCost = useCallback(
		(cost, saleAmount) => {
			if (unit === ServiceUnitEnum.Person) {
				const cantidadPax = passengersSelected.length;
				const totalCost = cost * cantidadPax;
				const totalSaleAmount = saleAmount * cantidadPax;
				// @ts-ignore
				setValue('totalCost', totalCost);
				// @ts-ignore
				setValue('totalSaleAmount', totalSaleAmount);
			}
		},
		[unit, passengersSelected],
	);

	const loadOidIssues = useCallback(async () => {
		try {
			onSetLoading(true);
			const oidOptionsResponse = (await getOIDIssues()).data;
			setOidIssuesList([{ ...noAplicaGenerico }, ...oidOptionsResponse]);
		} catch (error) {
			// @ts-ignore
			alert(error);
		} finally {
			onSetLoading(false);
		}
	}, [setOidIssuesList]);

	const loadCostLog = useCallback(async () => {
		const result = await getCostVariationLogsByService(saleOrderId, idService);
		setServiceCostVariationLogs(result.data);
	}, [setServiceCostVariationLogs]);

	useEffect(() => {
		reset();
		loadOidIssues();
		loadCostLog();
	}, [loadOidIssues, loadCostLog, service]);

	const handleUpdateService = async (data: IFlightService) => {
		try {
			onSetLoading(true);

			const flightService = data as IFlightService;
			// @ts-ignore
			flightService.markupPercentage = Number(flightService.markupPercentage);
			if (flightService.gds === 'n/a') {
				delete flightService.gds;
			}
			if (flightService.oidIssueId === 'n/a') {
				delete flightService.oidIssueId;
			}

			if (flightService.tariffProvider === 'n/a') {
				delete flightService.tariffProvider;
			} else if (typeof flightService.tariffProvider === 'string') {
				// @ts-ignore
				flightService.tariffProviderId = flightService.tariffProvider;
				delete flightService.tariffProvider;
			}
			// @ts-ignore
			if (flightService.tariffProviderId === 'n/a') {
				// @ts-ignore
				delete flightService.tariffProviderId;
			}
			if (flightService.passengersTickets) {
				// @ts-ignore
				flightService.passengersTickets = paxTickets.map((t, i) => {
					const ticket = document.getElementsByName(`passengersTickets.${i}.ticket`)[0];

					return {
						//@ts-ignore
						passengerId: t.passengerId,
						// @ts-ignore
						ticket: ticket.value,
					};
				});
			}

			if (flightService.flightLegs) {
				for (let flightLegIndex = 0; flightLegIndex < flightService.flightLegs.length; flightLegIndex++) {
					if (Boolean(flightService.flightLegs[flightLegIndex].keyId) === false) {
						flightService.flightLegs[flightLegIndex].keyId = nanoidGenerator();
					}
				}
			}

			if (flightLegs) {
				flightService.flightLegs = flightLegs.map((flightLeg) => ({
					...flightLeg,
					keyId: nanoidGenerator(),
				}));
			}

			if (
				flightService.unit === ServiceUnitEnum.Group &&
				flightService.cost !== undefined &&
				Number(flightService.cost) !== 0 &&
				flightService.saleAmount !== undefined &&
				Number(flightService.saleAmount) !== 0
			) {
				// @ts-ignore
				flightService.totalCost = Number(flightService.cost); // * flightService.passengerIds.length);
				// @ts-ignore
				flightService.totalSaleAmount = Number(flightService.saleAmount); // * flightService.passengerIds.length);
			}

			flightService.serviceType = TypeServiceEnum.Flight;

			if (flightService.withTst === 'manual') {
				flightService.flightLegs = [];
				// @ts-ignore
				flightService.withTst = false;
				// @ts-ignore
				delete flightService.tst;
			} else {
				// @ts-ignore
				flightService.withTst = true;
			}

			await putServiceForm({ saleOrderId, serviceId: flightService.keyId, data: flightService });
			onSetAlert({
				show: true,
				severity: 'success',
				message: 'Servicio guardado con exito',
			});
		} catch (error) {
			// @ts-ignore
			if (error.response) {
				// @ts-ignore
				alert(error.response.data.message);
			} else {
				console.log('error', error);
			}
		} finally {
			onSetLoading(false);
			onUpdateService();
		}
	};

	useEffect(() => {
		reset();
		remove();
		setFlightLegs([]);

		resetField('limitDate');

		const input = document.querySelector('input[name="limitDate"]');
		if (input) {
			// @ts-ignore
			input.value = '';
		}

		const effectTariffProviderId =
			typeof service.tariffProvider === 'string'
				? service.tariffProvider || 'n/a'
				: service.tariffProvider?.id || 'n/a';
		setValue('keyId' as `${string}.${string | number}`, service.keyId);
		setValue('reference' as `${string}.${string | number}`, service.reference || '');
		setValue('passengerIds' as `${string}.${string | number}`, service.passengerIds || '');

		setValue('gds' as `${string}.${string | number}`, service.gds ? service.gds : 'n/a');
		setValue('tariffProvider' as `${string}.${string | number}`, effectTariffProviderId);
		setValue('vc' as `${string}.${string | number}`, service.vc || '');
		setValue('carryOn' as `${string}.${string | number}`, service.carryOn || false);
		setValue('currency' as `${string}.${string | number}`, service.currency || 'USD');
		setValue(
			'baggageType' as `${string}.${string | number}`,
			service.baggageType ? service.baggageType : BaggageEnum.None,
		);
		setValue('oidIssueId' as `${string}.${string | number}`, service.oidIssueId ? service.oidIssueId : 'n/a');
		// @ts-ignore
		setValue('limitDate', service.limitDate || '');
		setValue(
			'unit' as `${string}.${string | number}`,
			service.unit.length === 0 ? ServiceUnitEnum.Person : service.unit,
		);
		setValue('cost' as `${string}.${string | number}`, service.cost || 0);
		setValue('markupPercentage' as `${string}.${string | number}`, service.markupPercentage || 0);
		setValue('saleAmount' as `${string}.${string | number}`, service.saleAmount || 0);
		setValue('totalCost' as `${string}.${string | number}`, service.totalCost || 0);
		setValue('totalSaleAmount' as `${string}.${string | number}`, service.totalSaleAmount || 0);
		setValue('tst' as `${string}.${string | number}`, service.tst || '');
		setValue('tqt' as `${string}.${string | number}`, service.tqt || '');
		setValue('voucherNotes' as `${string}.${string | number}`, service.voucherNotes || '');
		setValue('internalNotes' as `${string}.${string | number}`, service.internalNotes || '');
		// @ts-ignore
		setValue('withTst' as `${string}.${string | number}`, service.withTst === true ? 'tst' : 'manual');

		setValue('FXL', service.FXL || false);
		setValue('OPEROK', service.OPEROK || false);

		// @ts-ignore
		service.passengersTickets?.forEach((item) => {
			// @ts-ignore
			append({ passengerId: item.passengerId, ticket: item.ticket });
		});

		if (service.flightLegs?.length > 0) {
			setFlightLegs(service.flightLegs);
		}

		service.cost && handleCostChange(Number(service.cost));
		service.saleAmount && handleSaleAmountChange(Number(service.saleAmount));
	}, [setValue, index, service, reset, append, remove]);

	useEffect(() => {
		setValue('currency' as `${string}.${string | number}`, 'USD');
		setValue('carryOn' as `${string}.${string | number}`, false);
	}, [setValue]);

	return (
		<FormProvider {...methods}>
			<form
				onSubmit={methods.handleSubmit((data) => {
					handleUpdateService(data as IFlightService);
				})}
			>
				<div>
					<Grid container spacing={2} className={classes.form}>
						<Grid item xs={12} sm={12}>
							<Typography color={'Grey'} mb={1}>
								Pasajeros
							</Typography>
							<ControlledAutoComplete
								name={'passengerIds'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								options={passengers}
								isMultiple={true}
								helperText={checkShowErrorMessage(Boolean(errors.passengerIds), errors.passengerIds?.message)}
							/>
						</Grid>
						<Grid item xs={12}>
							{paxTickets.map((field, index) => (
								<Box display="flex" key={field.id} alignItems="end" gap={1}>
									<Typography color={'Grey'} width={300}>
										{/* 
										// @ts-ignore */}
										Ticket # {returnPaxName(passengers, getValues(`passengersTickets[${index}].passengerId`) as string)}
									</Typography>
									<input
										type="hidden"
										// @ts-ignore
										{...register(`passengersTickets.${index}.passengerId`)}
									/>
									<ControlledTextField
										name={`passengersTickets.${index}.ticket`}
										rules={{
											required: { value: true, message: 'Este campo es requerido' },
										}}
										helperText={checkShowErrorMessage(Boolean(errors.reference), errors.reference?.message)}
									/>
								</Box>
								// <div key={field.id}>
								// 	<input
								// 		name={`passengersTickets[${index}].passengerId`}
								// 		defaultValue={field.passengerId}
								// 		ref={register()}
								// 	/>
								// 	<input
								// 		name={`passengersTickets[${index}].ticket`}
								// 		defaultValue={field.ticket}
								// 		ref={register()}
								// 	/>
								// </div>
							))}
							{/* 							
							{Array.isArray(passengersSelected) &&
								passengersSelected.map((pax: string, paxIndex: number) => (
									<Box display="flex" key={paxIndex} alignItems="end" gap={1}>
										<Typography color={'Grey'} width={300}>
											Ticket # {returnPaxName(passengers, pax)}
										</Typography>
										<ControlledTextField
											name={`passengersTickets.${pax}`}
											rules={{
												required: { value: true, message: 'Este campo es requerido' },
											}}
											helperText={checkShowErrorMessage(Boolean(errors.reference), errors.reference?.message)}
										/>
									</Box>
								))} */}
						</Grid>
						<Grid item xs={12} sm={6}>
							<Typography color={'Grey'}>Referencia</Typography>
							<ControlledTextField
								name={'reference'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								helperText={checkShowErrorMessage(Boolean(errors.reference), errors.reference?.message)}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Typography color={'Grey'}>GDS</Typography>
							<ControlledSelect
								name={'gds'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								options={gdsOptions}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Typography color={'Grey'}>Proveedor de tarifas</Typography>
							<AutocompleteProviders
								allowNotApplicable
								name={'tariffProvider'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Typography color={'Grey'}>Validating carrier</Typography>
							<Controller
								control={control}
								// @ts-ignore
								name={'vc'}
								rules={{ required: 'Este campo es requerido' }}
								render={({ field }) => (
									<>
										<Select
											className={classes.selected}
											{...field}
											size={'small'}
											value={field.value ? field.value.code : ''}
											onChange={(event) => {
												const selectedValue = event.target.value;
												const selectedItem = validatingCarriers?.find((item) => item.code === selectedValue);
												field.onChange(selectedItem);
											}}
										>
											{validatingCarriers?.map((item) => (
												<MenuItem key={item.code} value={item.code}>
													{item.name}
												</MenuItem>
											))}
										</Select>
										{errors['vc'] && <FormHelperText sx={{ color: 'red' }}>{errors['vc']?.message}</FormHelperText>}
									</>
								)}
							/>
						</Grid>
						{/* <Grid item xs={12} sm={4}>
							<Typography color={'Grey'}>Equipaje</Typography>
							<ControlledSelect
								name={'baggageType'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								options={baggageOptions}
							/>
						</Grid> */}
						<Grid item xs={12} sm={4}>
							<Typography color={'Grey'}>OID de emision</Typography>
							<ControlledSelect
								name={'oidIssueId'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								options={oidIssuesList?.map((item) => ({ id: item?.id?.toString(), name: item?.name }))}
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Typography color={'Grey'}>Fecha limite</Typography>
							<ControlledDateTimePicker
								name={'limitDate'}
								rules={{ required: 'Este campo es requerido' }}
								required={true}
							/>
						</Grid>
						{/* <Grid item xs={12} sm={12}>
							<ControlledCheckBox
								name={'carryOn'}
								label="Carry On"
								onChange={(isChecked) => setValue('carryOn' as `${string}.${string | number}`, isChecked)}
							/>
						</Grid> */}
						<Grid item xs={12} sm={3}>
							<Typography color={'Grey'}>Unidad por</Typography>
							<ControlledSelect
								name={'unit'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								options={excludeTypeUnits(typeUnit, ['Habitacion', 'Vehiculo', 'Camarote'])}
								iconTypeTrip={iconMapping}
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<Typography color={'Grey'}>Moneda</Typography>
							<ControlledSelect
								name={'currency'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								options={currencyesList.map((item) => ({ id: item.code.toString(), name: item.code }))}
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<Typography color={'Grey'}>Costo</Typography>
							<ControlledTextField
								name={'cost'}
								isNumber
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								onChange={(value) => handleCostChange(parseFloat(value))}
								helperText={checkShowErrorMessage(Boolean(errors.cost), errors.cost?.message)}
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<Typography color={'Grey'}>Markup %</Typography>
							<ControlledTextField
								isNumber
								name={'markupPercentage'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								onChange={(value) => handleMarkupPercentageChange(parseFloat(value))}
								helperText={checkShowErrorMessage(Boolean(errors.markupPercentage), errors.markupPercentage?.message)}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<Typography color={'Grey'}>Venta</Typography>
							<ControlledTextField
								isNumber
								name={'saleAmount'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								onChange={(value) => handleSaleAmountChange(parseFloat(value))}
								helperText={checkShowErrorMessage(Boolean(errors.saleAmount), errors.saleAmount?.message)}
							/>
						</Grid>

						{unit === ServiceUnitEnum.Person && (
							<>
								<Grid item xs={12} sm={6}>
									<Typography color={'Grey'}>Costo Total</Typography>
									<ControlledTextField name={'totalCost'} disabled />
								</Grid>
								<Grid item xs={12} sm={6}>
									<Typography color={'Grey'}>Venta Total</Typography>
									<ControlledTextField name={'totalSaleAmount'} disabled />
								</Grid>
							</>
						)}
						{
							<Grid item xl={2} xs={3} sm={6}>
								<ControlledCheckBox name={'FXL'} label="FXL" onChange={(isChecked) => setValue('FXL', isChecked)} />
							</Grid>
						}
						{
							<Grid item xl={2} xs={3} sm={6}>
								<ControlledCheckBox
									name={'OPEROK'}
									label="OPEROK"
									onChange={(isChecked) => setValue('OPEROK', isChecked)}
								/>
							</Grid>
						}
						<Grid item xs={12}>
							<Typography color={'Grey'}>Aire con TST o Manual</Typography>
							<ControlledSelect
								name={'withTst'}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								options={[
									{
										id: 'tst',
										name: 'Con Tst',
									},
									{
										id: 'manual',
										name: 'Manual',
									},
								]}
							/>
						</Grid>
						{withTst === 'tst' ? (
							<>
								<Grid item xs={12} sm={12}>
									<Typography color={'Grey'}>TST</Typography>
									<Controller
										// @ts-ignore
										name={'tst'}
										control={control}
										render={({ field }) => (
											<TextField
												{...field}
												onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
													const inputValue = e.target.value;
													field.onChange(inputValue);
													handleGdsRawDataChange(inputValue);
												}}
												value={field.value}
												fullWidth
												variant="outlined"
												multiline
												rows={10}
												helperText={checkShowErrorMessage(Boolean(errors.internalNotes), errors.internalNotes?.message)}
											/>
										)}
									/>
								</Grid>
							</>
						) : (
							isExistingService && (
								<Box display="flex" mt={2} pl={2}>
									<TextField
										size="small"
										label="Numero de vuelo"
										value={flightLeg.flightNumber}
										onChange={handleFlightNumberChange}
										style={{ width: 80 }}
									/>
									{/* <TextField label="Aerolinea" value={flightLeg.airlineCode} onChange={handleAirlineCodeChange} /> */}

									<Select size="small" value={flightLeg.airlineCode} onChange={handleAirlineCodeChange}>
										<MenuItem key="noselection" value="noselection">
											Aerolinea
										</MenuItem>
										{validatingCarriers.map((option) => (
											<MenuItem key={option.code} value={option.code}>
												{option.name}
											</MenuItem>
										))}
									</Select>

									{/* <TextField
									label="Salida de"
									value={flightLeg.departure.airportCode}
									onChange={handleDepartureAirportCodeChange}
								/> */}

									{/* <Select value={flightLeg.departure.airportCode} onChange={handleDepartureAirportCodeChange}>
									<MenuItem key="noselection" value="noselection">
										Salida de
									</MenuItem>
									{airports.map((option) => (
										<MenuItem key={option.code} value={option.code}>
											{option.code} - {option.name}
										</MenuItem>
									))}
								</Select> */}
									<Autocomplete
										value={
											flightLeg.departure.airportCode === null
												? null
												: airportsOptions.find((opt) => opt.id === flightLeg.departure.airportCode)
										}
										onChange={handleDepartureAirportCodeChange}
										options={airportsOptions}
										getOptionLabel={(option: OpcionAirport) => option.label}
										renderInput={(params) => (
											<TextField {...params} size="small" label="Salida de" variant="outlined" />
										)}
										style={{ width: 280 }}
									/>
									<TextField
										size="small"
										label="Fecha y Hora de Salida"
										type="datetime-local"
										InputLabelProps={{ shrink: true }}
										value={`${flightLeg.departure.date}T${flightLeg.departure.hour}`}
										onChange={handleDepartureDateTimeChange}
									/>

									<Autocomplete
										value={
											flightLeg.arrival.airportCode === null
												? null
												: airportsOptions.find((opt) => opt.id === flightLeg.arrival.airportCode)
										}
										onChange={handleArrivalAirportCodeChange}
										options={airportsOptions}
										getOptionLabel={(option: OpcionAirport) => option.label}
										renderInput={(params) => (
											<TextField {...params} size="small" label="Llegada a" variant="outlined" />
										)}
										style={{ width: 280 }}
									/>

									{/* <Select value={flightLeg.arrival.airportCode} onChange={handleArrivalAirportCodeChange}>
									<MenuItem key="noselection" value="noselection">
										LLegada a
									</MenuItem>
									{airports.map((option) => (
										<MenuItem key={option.code} value={option.code}>
											{option.code} - {option.name}
										</MenuItem>
									))}
								</Select> */}
									{/* <TextField
									label="Llegada a"
									value={flightLeg.arrival.airportCode}
									onChange={handleArrivalAirportCodeChange}
								/> */}
									<TextField
										label="Fecha y Hora de Llegada"
										size="small"
										type="datetime-local"
										InputLabelProps={{ shrink: true }}
										value={`${flightLeg.arrival.date}T${flightLeg.arrival.hour}`}
										onChange={handleArrivalDateTimeChange}
									/>
								</Box>
							)
						)}
						{(withTst === 'tst' || (withTst !== 'tst' && isExistingService)) && (
							<Grid item xs={12} sm={12}>
								<Button
									type="button"
									fullWidth
									variant="contained"
									onClick={withTst === 'tst' ? handleAddTST : handleAddLeg}
								>
									Agregar Aire
								</Button>
							</Grid>
						)}
						{flightLegs && (
							<FlightsTable
								flightLegs={flightLegs}
								saleOrderId={saleOrderId}
								serviceId={idService}
								onUpdateService={onUpdateService}
								onSetAlert={onSetAlert}
								onSetLoading={onSetLoading}
								allowDelete={withTst === 'manual'}
							/>
						)}
						<Grid item xs={12} sm={12}>
							<Typography color={'Grey'}>TQT</Typography>
							<Controller
								// @ts-ignore
								name={'tqt'}
								control={control}
								render={({ field }) => (
									<TextField
										{...field}
										onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
											const inputValue = e.target.value;
											field.onChange(inputValue);
										}}
										value={field.value}
										fullWidth
										variant="outlined"
										multiline
										rows={10}
										helperText={checkShowErrorMessage(Boolean(errors.internalNotes), errors.internalNotes?.message)}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Typography color={'Grey'}>Notas de voucher</Typography>
							<Controller
								// @ts-ignore
								name={'voucherNotes'}
								control={control}
								defaultValue="Favor de presentar voucher en el check-in"
								render={({ field }) => (
									<TextField
										fullWidth
										variant="outlined"
										multiline
										rows={5}
										{...field}
										helperText={checkShowErrorMessage(Boolean(errors.voucherNotes), errors.voucherNotes?.message)}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Typography color={'Grey'}>
								<LockIcon /> Notas internas
							</Typography>
							<Controller
								// @ts-ignore
								name={'internalNotes'}
								control={control}
								render={({ field }) => (
									<TextField
										fullWidth
										variant="outlined"
										multiline
										rows={5}
										{...field}
										helperText={checkShowErrorMessage(Boolean(errors.internalNotes), errors.internalNotes?.message)}
									/>
								)}
							/>
						</Grid>
						<ServiceCostVariationLogs filteredArray={filteredArray} />
					</Grid>
					{
						// @ts-ignore
						(service.enable || service.enable === undefined) && (
							<Box display="flex" flexDirection="row-reverse" mt={2}>
								<Button type="submit" variant="contained" color="success">
									Guardar
								</Button>
							</Box>
						)
					}
				</div>
			</form>
		</FormProvider>
	);
};
