import { FilterType, IHeadCell, OperatorFilterEnum, SelectFilterModelSimple } from 'features/common/types';
import { serviceTypes } from 'features/salesOrder/types';

export const headerCells: IHeadCell[] = [
	{
		field: 'created',
		sortable: true,
		headerName: 'Fecha',
	},
	{
		field: 'createdBy',
		sortable: false,
		headerName: 'Creada por',
	},
	{
		field: 'dealId',
		sortable: true,
		headerName: 'Deal ID',
	},
	{
		field: 'mainPassengerCompleteName',
		sortable: true,
		headerName: 'Pasajero Principal',
	},
	{
		field: 'quotationId',
		sortable: true,
		headerName: 'Quotation ID',
	},
	{
		field: 'currency',
		sortable: true,
		headerName: 'Moneda',
	},
	{
		field: 'salesAmount',
		sortable: true,
		headerName: 'Venta',
	},
	{
		field: 'totalCost',
		sortable: true,
		headerName: 'Costo',
	},
	{
		field: 'grossProfit',
		sortable: true,
		headerName: 'Margen Bruto',
	},
	{
		field: 'chargedAmount',
		sortable: true,
		headerName: 'Cobro',
	},
	{
		field: 'pendingPayAmount',
		sortable: true,
		headerName: 'Saldo',
	},
];

export const saleOrderFilters = [
	{ label: 'Fecha', type: FilterType.DATE, key: 'created' },
	{
		label: 'Número de Orden',
		type: FilterType.NUMERIC,
		key: 'orderNumber',
		operator: OperatorFilterEnum.EQUALS,
	},
	{
		label: 'Deal ID',
		type: FilterType.NUMERIC,
		key: 'dealId',
		operator: OperatorFilterEnum.EQUALS,
	},
	{
		label: 'Quotations ID',
		type: FilterType.NUMERIC,
		key: 'quotationId',
		operator: OperatorFilterEnum.EQUALS,
	},
	{ label: 'Venta', type: FilterType.NUMERIC, key: 'salesAmount' },
	{ label: 'Costo', type: FilterType.NUMERIC, key: 'totalCost' },
	{ label: 'Utilidad', type: FilterType.NUMERIC, key: 'grossProfit' },
	{ label: 'Pago', type: FilterType.NUMERIC, key: 'chargedAmount' },
	{ label: 'Saldo', type: FilterType.NUMERIC, key: 'pendingPayAmount' },
	{
		label: 'Destinos',
		type: FilterType.STRING,
		key: 'destinations',
		operator: OperatorFilterEnum.CONTAINS,
	},
	{ label: 'Fecha de ida', type: FilterType.DATE, key: 'travelDepartureDate' },
	{ label: 'Fecha de vuelta', type: FilterType.DATE, key: 'travelReturnDate' },
	new SelectFilterModelSimple('Servicios contratados', 'servicesTypes', serviceTypes),
	{
		label: 'Pasajero principal',
		type: FilterType.STRING,
		key: 'mainPassengerCompleteName',
		operator: OperatorFilterEnum.CONTAINS,
	},
	{ label: 'Última edicón', type: FilterType.DATE, key: 'updated' },
	{
		label: 'Vendedor',
		type: FilterType.STRING,
		key: 'createdBy',
		operator: OperatorFilterEnum.CONTAINS,
	},
	{
		label: 'Cliente',
		type: FilterType.STRING,
		key: 'customerCompleteName',
		operator: OperatorFilterEnum.CONTAINS,
	},
];
