import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Button, Typography, Backdrop, CircularProgress } from '@mui/material';
import GroupsIcon from '@mui/icons-material/Groups';

import { IPassenger, IPassengerForm, typeNationalites } from 'features/salesOrder/types';
import { nanoidGenerator } from 'features/common/helpers';

import { RootState } from 'store';
import { useStyles } from 'features/salesOrder/components/SalesOrderPassagerForm/styles';
import { PassengerForm } from './PassengerForm';
import { countries } from 'features/common/constants';
import { removePax } from 'features/salesOrder/services';
import { useParams } from 'react-router-dom';
import { ShowAlertState } from 'features/common/types';

const defaultNationality = (mercado: string) =>
	typeNationalites.find((nationality) => nationality.flagsCode === mercado);

const transformedNationality = ({ countries, mercado }: any) => {
	const nationality = defaultNationality(mercado);
	return nationality
		? {
				code: countries.find((country: any) => country.code === nationality.flagsCode)?.plCitizenCode || '',
				name: nationality.name,
		  }
		: {
				code: '',
				name: '',
		  };
};

interface Props {
	passengers: IPassenger[];
	onUpdatePassengers: () => void;
	onSetAlert: (values: ShowAlertState) => void;
}

export const Paxes = ({ passengers, onUpdatePassengers, onSetAlert }: Props): JSX.Element => {
	const classes = useStyles();
	const { id: saleOrderId } = useParams<{ id: string }>();
	const { auth } = useSelector((state: RootState) => state);
	const [passengersState, setPassengersState] = useState<IPassengerForm[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [hasOptionSelected, setHasOptionSelected] = useState(false);
	const mercado = auth.country;

	const handleRemovePax = useCallback(
		async (keyId: string) => {
			const newState = passengersState.filter((pax) => pax.keyId !== keyId);
			const isPaxInDb = Boolean(passengers.find((pax) => pax.keyId === keyId));

			setPassengersState(newState);

			if (isPaxInDb) {
				try {
					setLoading(true);
					await removePax({ saleOrderId, paxId: keyId });
					onUpdatePassengers();
					onSetAlert({
						show: true,
						severity: 'success',
						message: 'Pasajero removido con exito!',
					});
				} catch (e) {
					console.log(e);

					onSetAlert({
						show: true,
						severity: 'error',
						message: 'Error al remover el pasajero',
					});
				} finally {
					setLoading(false);
				}
			}
		},
		[passengersState, setPassengersState, passengers],
	);

	const handleAddPassenger = useCallback(() => {
		const isFirstPassenger = passengersState.length === 0;
		const nationality = transformedNationality({ countries, mercado });
		const newPassenger: IPassengerForm = {
			id: nanoidGenerator(),
			keyId: nanoidGenerator(),
			name: '',
			lastName: '',
			birthdate: '',
			mainPassenger: isFirstPassenger,
			nationality: nationality?.code,
			documents: [],
		};
		setPassengersState((prev) => [...prev, newPassenger]);
	}, [passengersState, setPassengersState]);

	useEffect(() => {
		const newState = passengers.map((pax) => ({
			...pax,
			nationality: pax.nationality.code,
			documents: pax.documents.map((doc) => ({
				...doc,
				country: doc.country.code,
			})),
		}));
		setPassengersState(newState);
	}, [passengers]);

	useEffect(() => {
		if (hasOptionSelected) {
			setHasOptionSelected(false);
		}
	}, [hasOptionSelected]);

	console.log('seba', { passengers });

	return (
		<>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Grid className={classes.container}>
				<Grid container className={classes.containerHeader}>
					<Grid className={classes.header}>
						<GroupsIcon className={classes.icon} />
						<Typography variant="h6" pl={2} className={classes.names}>
							Pasajeros
							{passengers.map((passenger, index) => {
								return (
									<Typography variant="subtitle1" key={index} color={'black'} ml={2} mt={0.5}>
										{passenger.name || ''} {passenger.lastName || ''}
									</Typography>
								);
							})}
						</Typography>
					</Grid>
				</Grid>

				<Grid className={classes.main}>
					{passengersState.map((passenger) => (
						<PassengerForm
							isInDb={Boolean(passengers.find((pax) => pax.keyId === passenger.keyId))}
							key={passenger.keyId}
							passenger={passenger}
							onRemove={handleRemovePax}
							onUpdatePassengers={onUpdatePassengers}
							onSetAlert={onSetAlert}
							onSetLoading={setLoading}
						/>
					))}

					<Grid item xs={12} sm={12} className={classes.containerButtom}>
						<Button className={classes.buttonAdd} variant="outlined" onClick={handleAddPassenger}>
							+ Agregar pasajero
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
