import React, { useState } from 'react';
import {
	IAccommodationService,
	ICodeName,
	IFlightService,
	IPassenger,
	IServices,
	ITransferService,
	TypeServiceEnum,
} from 'features/salesOrder/types';

import { Backdrop, Box, CircularProgress } from '@mui/material';
import { FlightService } from './FlightService';
import { IValidatingCarrier } from 'features/transactions/types';
import { AccommodationService } from './AccomodationService';
import { TransferService } from './TransferService';
import { OtherService } from './OtherService';
import { ShowAlertState } from 'features/common/types';
import { useStyles } from 'features/salesOrder/components/SalesOrderPassagerForm/styles';

interface Props {
	service?: IServices;
	serviceIndex?: number;
	passengers: IPassenger[];
	validatingCarriers: IValidatingCarrier[];
	currencyesList: ICodeName[];
	isExistingService?: boolean;
	onUpdateService: () => void;
	onSetAlert: (values: ShowAlertState) => void;
	isEnabled?: boolean;
}

export const ServiceForm = ({
	service,
	passengers,
	serviceIndex,
	validatingCarriers,
	currencyesList,
	onUpdateService,
	onSetAlert,
	isExistingService,
}: Props): JSX.Element => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	return (
		<Box
			display="flex"
			flexDirection="column"
			gap={2}
			p={2}
			sx={{
				// border: '1px solid rgba(0, 0, 0, 0.12)',
				// background: '#f5f5f5',
				borderRadius: '4px',
			}}
		>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Box>
				{service?.serviceType === TypeServiceEnum.Flight && serviceIndex !== undefined && (
					<FlightService
						index={serviceIndex}
						passengers={passengers}
						// @ts-ignore
						service={service as IFlightService}
						currencyesList={currencyesList}
						validatingCarriers={validatingCarriers}
						onUpdateService={onUpdateService}
						onSetAlert={onSetAlert}
						onSetLoading={setLoading}
						isExistingService={isExistingService}
					/>
				)}
				{service?.serviceType === TypeServiceEnum.Accommodation && serviceIndex !== undefined && (
					<AccommodationService
						index={serviceIndex}
						passengers={passengers}
						// @ts-ignore
						service={service as IAccommodationService}
						currencyesList={currencyesList}
						onUpdateService={onUpdateService}
						onSetAlert={onSetAlert}
						onSetLoading={setLoading}
					/>
				)}
				{service?.serviceType === TypeServiceEnum.Transfer && serviceIndex !== undefined && (
					<TransferService
						index={serviceIndex}
						passengers={passengers}
						// @ts-ignore
						service={service as ITransferService}
						currencyesList={currencyesList}
						onUpdateService={onUpdateService}
						onSetAlert={onSetAlert}
						onSetLoading={setLoading}
					/>
				)}
				{service?.serviceType === TypeServiceEnum.Other && serviceIndex !== undefined && (
					<OtherService
						index={serviceIndex}
						passengers={passengers}
						// @ts-ignore
						service={service as ITransferService}
						currencyesList={currencyesList}
						onUpdateService={onUpdateService}
						onSetAlert={onSetAlert}
						onSetLoading={setLoading}
					/>
				)}
			</Box>
		</Box>
	);
};
