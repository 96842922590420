import React, { useState, useCallback, useRef } from 'react';
import Modal from '@mui/material/Modal';
import { Box, Button, AlertColor, Alert, Snackbar, Grid, Typography, Autocomplete, TextField } from '@mui/material';

import { extractErrorMessage } from 'features/quotation/helpers';
import { getCustomerAutocomplete, asociateCustomerToSaleOrder } from 'features/salesOrder/services';
import { ICustomersAutocomplete, SegmentEnum } from 'features/salesOrder/types';

interface Props {
	open: boolean;
	handleClose: () => void;
	handleOptionSelection: (customerId: string) => void;
	segmentType: SegmentEnum;
	saleOrderId: string;
	handleCreateCustomer: () => void;
}
interface ShowAlertState {
	show: boolean;
	severity: AlertColor;
	message: string;
}

export function CustomerModal({
	open,
	handleClose,
	handleOptionSelection,
	segmentType,
	saleOrderId,
	handleCreateCustomer,
}: Props): JSX.Element {
	const defaultAlertState: ShowAlertState = { show: false, severity: 'success', message: '' };
	const [alert, setAlert] = useState<ShowAlertState>(defaultAlertState);
	const [optionsModal, setOptionsModal] = useState<any[]>([]);
	const [customerSelected, setCustomerSelected] = React.useState<ICustomersAutocomplete | null>(null);
	const [inputValue, setInputValue] = useState('');
	const [isOptionSelected, setIsOptionSelected] = useState(false);
	const autocompleteDebouncer = useRef<number | null>(null);

	const style = {
		position: 'absolute' as const,
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 700,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		pt: 2,
		px: 4,
		pb: 3,
	};

	const fetchOptions = useCallback(
		async (valueToSearch): Promise<ICustomersAutocomplete[]> => {
			try {
				const response = await getCustomerAutocomplete(valueToSearch, segmentType);
				return response.data;
			} catch (error) {
				setAlert({
					show: true,
					severity: 'error',
					message: extractErrorMessage(error, 'Ocurrió un error al seleccionar'),
				});
				return [];
			}
		},
		[inputValue, optionsModal],
	);
	const inputChangeHandler = (value: string) => {
		setInputValue(value);
		if (autocompleteDebouncer.current !== null) {
			clearTimeout(autocompleteDebouncer.current);
		}

		if (value.length >= 4) {
			autocompleteDebouncer.current = setTimeout(async () => {
				try {
					fetchOptions(inputValue).then((results) => {
						setOptionsModal(results);
					});
				} catch (error) {
					setAlert({
						show: true,
						severity: 'error',
						message: extractErrorMessage(error, 'Ocurrió un error al completar los datos'),
					});
				}
				300;
			});
		} else {
			setOptionsModal([]);
		}
	};

	const patchCustomer = async (id: string, customerId: string) => {
		try {
			await asociateCustomerToSaleOrder(id, customerId);
			setAlert({
				show: true,
				severity: 'success',
				message: 'Cliente asociado con éxito',
			});
			handleClose();
		} catch (error) {
			setAlert({
				show: true,
				severity: 'error',
				message: extractErrorMessage(error, 'Ocurrió un error al actualizar los servicios'),
			});
			handleClose();
		}
	};

	return (
		<>
			<Modal open={open} aria-labelledby="child-modal-title" aria-describedby="child-modal-description">
				<Box sx={{ ...style, textAlign: 'center' }}>
					<Button sx={{ ml: 80, p: 0, width: 5 }} onClick={handleClose}>
						X
					</Button>
					<Typography marginBottom={3}> Buscar Cliente</Typography>
					<Grid container sx={{ justifyContent: 'center' }}>
						<Autocomplete
							getOptionLabel={(option) =>
								option === 'string'
									? option
									: [`${option.customerName} ${option.email} ${option.taxIdentificationNumber} ${option.countryName}`]
											.filter(Boolean)
											.join('')
							}
							filterOptions={(x) => x}
							options={optionsModal}
							autoComplete
							includeInputInList
							filterSelectedOptions
							value={customerSelected}
							onChange={(_, newValue) => {
								setCustomerSelected(newValue);
								setIsOptionSelected(!!newValue);
							}}
							onInputChange={async (_, newValue) => {
								inputChangeHandler(newValue);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									variant="outlined"
									fullWidth
									size="small"
									InputLabelProps={{ shrink: true }}
									sx={{ width: 500, pb: 3 }}
								/>
							)}
						/>
					</Grid>
					<Button
						variant="contained"
						color="success"
						onClick={() => {
							if (isOptionSelected && customerSelected?.customerId) {
								handleOptionSelection(customerSelected.customerId);
								patchCustomer(saleOrderId, customerSelected.customerId);
							}
						}}
						disabled={!isOptionSelected}
					>
						Asociar cliente
					</Button>
					{segmentType === SegmentEnum.LEISURE && (
						<Button
							variant="contained"
							color="primary"
							sx={{ ml: 1 }}
							onClick={() => {
								handleCreateCustomer();
							}}
						>
							Crear cliente
						</Button>
					)}
				</Box>
			</Modal>

			<Snackbar open={alert.show} autoHideDuration={1500} onClose={() => setAlert(defaultAlertState)}>
				<Alert variant="filled" severity={alert.severity}>
					{alert.message}
				</Alert>
			</Snackbar>
		</>
	);
}
