import React, { useCallback, useEffect, useState } from 'react';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';

import { useTheme, Box, Button, Divider, Grid, TextField, Typography } from '@mui/material';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';

import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useStyles } from 'features/salesOrder/components/SalesOrderPassagerForm/styles';
import { OrderFormFieldsMaxLength } from 'features/payment/types';
import { checkShowErrorMessage, nanoidGenerator } from 'features/common/helpers';
import {
	IDocumentForm,
	IDocuments,
	IPassenger,
	IPassengerForm,
	PassengerDocumentEnum,
	passengerDocumentType,
	typeNationalites,
} from 'features/salesOrder/types';
// import { DragDrop } from 'features/salesOrder/components/SalesOrderPassagerForm/dragDrop';
import { countries, emailPattern } from 'features/common/constants';
import { PassagerModal } from 'features/salesOrder/components/SaleOrderPassagerModal';
import { getPax, updatePax } from 'features/salesOrder/services';
import { useParams } from 'react-router-dom';
import { ControlledSelect } from 'features/common/components/ControlledSelect';
import { ControlledDatePicker } from 'features/common/components/ControlledDatePicker';
import { ControlledTextField } from 'features/common/components/ControlledTextField';
import { ControlledCheckBox } from 'features/common/components/ControlledCheckBox';
import { ShowAlertState } from 'features/common/types';

interface Props {
	passenger: IPassengerForm;
	onUpdatePassengers: () => void;
	onRemove: (keyId: string) => void;
	isInDb?: boolean;
	onSetAlert: (values: ShowAlertState) => void;
	onSetLoading: (value: boolean) => void;
}

export const PassengerForm = ({
	onUpdatePassengers,
	onRemove,
	passenger,
	onSetAlert,
	onSetLoading,
	isInDb,
}: Props): JSX.Element => {
	const [documents, setDocuments] = useState<IDocumentForm[]>([]);
	const classes = useStyles();
	const theme = useTheme();
	const { id: saleOrderId } = useParams<{ id: string }>();
	const [openModal, setOpenModal] = useState(false);

	const toggleModal = () => setOpenModal((prev) => !prev);

	const methods = useForm<IPassengerForm>({ mode: 'all' });
	const {
		control,
		setValue,
		register,
		formState: { errors },
	} = methods;

	const { remove } = useFieldArray({
		control, // control props comes from useForm (optional: if you are using FormProvider)
		name: 'documents', // unique name for your Field Array
	});

	const handleOptionSelection = async (paxId: string) => {
		try {
			const { data } = await getPax({ paxId });
			const parsedPax: IPassengerForm = {
				...data,
				nationality: data.nationality.code,
				documents: data.documents.map((doc: IDocuments) => ({
					...doc,
					country: doc.country.code,
				})),
			};
			parsedPax.documents && setDocuments(parsedPax.documents);

			setValue('keyId', parsedPax.keyId, { shouldDirty: true });
			setValue('name', parsedPax.name, { shouldDirty: true });
			setValue('lastName', parsedPax.lastName, { shouldDirty: true });
			setValue('nationality', parsedPax.nationality, { shouldDirty: true });
			setValue('birthdate', parsedPax.birthdate, { shouldDirty: true });
			setValue('ageAtEndTravel', parsedPax.ageAtEndTravel, { shouldDirty: true });
			setValue('phone', parsedPax.phone, { shouldDirty: true });
			setValue('email', parsedPax.email, { shouldDirty: true });
			setValue('mainPassenger', parsedPax.mainPassenger, { shouldDirty: true });
			setValue('documents', parsedPax.documents, { shouldDirty: true });
		} catch (e) {
			console.log(e);
			onSetAlert({
				show: true,
				severity: 'error',
				message: 'Error al traer pasajero',
			});
		}
	};

	const handleRemovePax = useCallback(() => {
		onRemove(passenger.keyId);
	}, [passenger]);

	const handleAddDocument = () => {
		const newDoc = {
			keyId: nanoidGenerator(),
			documentType: PassengerDocumentEnum.CEDULA,
			country: '',
			documentNumber: '',
			expirationDate: '',
		};

		setDocuments((prevState) => {
			const newState = [...prevState, newDoc];
			setValue('documents', newState, { shouldDirty: true });
			return newState;
		});
	};

	const handleDeleteDocument = (docIndex: number) => () => {
		setDocuments((prevState) => {
			const newState = [...prevState];
			newState.splice(docIndex, 1);
			remove(docIndex);
			return newState;
		});
	};

	const handleSavePassenger = async (data: IPassengerForm) => {
		try {
			onSetLoading(true);
			const a = countries.find((country) => country.plCitizenCode === data.nationality);

			const payload: IPassenger = {
				...data,
				nationality: {
					code: data.nationality,
					name: `${a?.name}`,
				},
				mainPassenger: Boolean(data.mainPassenger),
				documents: data.documents.map((doc) => {
					const b = countries.find((country) => country.code === doc.country);
					const expirationDate = doc.expirationDate;

					return {
						...doc,
						keyId: doc.keyId,
						expirationDate,
						country: {
							code: doc.country,
							name: `${b?.name}`,
						},
					};
				}),
			};

			await updatePax({
				saleOrderId,
				paxId: passenger.keyId,
				payload,
			});
			onSetAlert({
				show: true,
				severity: 'success',
				message: 'Pasajero guardado!',
			});
			onUpdatePassengers();
		} catch (_e) {
			console.log(_e);
			onSetAlert({
				show: true,
				severity: 'error',
				message: 'Error al guardar pax :(',
			});
		} finally {
			onSetLoading(false);
		}
	};

	useEffect(() => {
		passenger.documents && setDocuments(passenger.documents);
		setValue('id', passenger.id, { shouldDirty: true });
		setValue('keyId', passenger.keyId, { shouldDirty: true });
		setValue('name', passenger.name, { shouldDirty: true });
		setValue('lastName', passenger.lastName, { shouldDirty: true });
		setValue('nationality', passenger.nationality, { shouldDirty: true });
		setValue('birthdate', passenger.birthdate, { shouldDirty: true });
		setValue('ageAtEndTravel', passenger.ageAtEndTravel, { shouldDirty: true });
		setValue('phone', passenger.phone, { shouldDirty: true });
		setValue('email', passenger.email, { shouldDirty: true });
		setValue('mainPassenger', passenger.mainPassenger, { shouldDirty: true });
		setValue('documents', passenger.documents, { shouldDirty: true });
	}, [passenger, setValue]);

	return (
		<FormProvider {...methods}>
			<form
				onSubmit={methods.handleSubmit((data) => {
					handleSavePassenger(data as IPassengerForm);
				})}
			>
				<Grid container spacing={2} className={classes.card}>
					<Grid item xs={12} sm={3}>
						<Grid item xs={12} sm={11} className={classes.data}>
							<Button onClick={toggleModal} startIcon={<PersonSearchIcon />} variant="outlined" fullWidth>
								<Typography className={classes.title}>BUSCAR PAX</Typography>
							</Button>
							{openModal && (
								<PassagerModal
									open={openModal}
									handleClose={toggleModal}
									handleOptionSelection={handleOptionSelection}
								/>
							)}
						</Grid>
						<Grid item xs={12} sm={11} className={classes.data}>
							<Typography className={classes.title}>Nombres</Typography>
							<Controller
								name="name"
								control={control}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								render={({ field }) => (
									<TextField
										variant="outlined"
										required
										fullWidth
										size="small"
										className={classes.textField}
										helperText={checkShowErrorMessage(Boolean(errors.name), errors.name?.message)}
										error={Boolean(errors.name)}
										{...field}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={11} className={classes.data}>
							<Typography className={classes.title}>Apellidos</Typography>
							<Controller
								name="lastName"
								control={control}
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								render={({ field }) => (
									<TextField
										variant="outlined"
										required
										fullWidth
										size="small"
										className={classes.textField}
										helperText={checkShowErrorMessage(Boolean(errors.lastName), errors.lastName?.message)}
										error={Boolean(errors.lastName)}
										{...field}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={11} className={classes.data}>
							<Typography className={classes.title}>Nacionalidad</Typography>
							<ControlledSelect
								name="nationality"
								rules={{
									required: { value: true, message: 'Este campo es requerido' },
								}}
								options={countries.map((item) => ({ id: item.plCitizenCode, name: item.name }))}
							/>
						</Grid>
						<Grid item xs={12} sm={11} className={classes.data}>
							<Typography className={classes.title}>Fecha de Nacimiento</Typography>
							<ControlledDatePicker
								name={'birthdate'}
								rules={{ required: 'Este campo es requerido' }}
								inputFormat="dd/MM/yyyy"
								required={true}
							/>
						</Grid>
						<Grid item xs={12} sm={11} className={classes.data}>
							<Typography className={classes.title}>Telefono</Typography>
							<Controller
								name="phone"
								control={control}
								rules={{
									maxLength: {
										value: OrderFormFieldsMaxLength.PHONE,
										message:
											'El telefono excede el largo máximo permitido de: ' +
											OrderFormFieldsMaxLength.PHONE +
											' caracteres',
									},
								}}
								render={({ field }) => (
									<TextField
										variant="outlined"
										fullWidth
										size="small"
										InputLabelProps={{ shrink: true }}
										className={classes.textField}
										helperText={checkShowErrorMessage(Boolean(errors.phone), errors.phone?.message)}
										error={Boolean(errors.phone)}
										{...field}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={11} className={classes.data}>
							<Typography className={classes.title}>Email</Typography>
							<Controller
								name="email"
								control={control}
								rules={{
									maxLength: {
										value: OrderFormFieldsMaxLength.EMAIL,
										message:
											'El correo electrónico excede el largo máximo permitido de: ' +
											OrderFormFieldsMaxLength.EMAIL +
											' caracteres',
									},
									pattern: {
										value: emailPattern,
										message: 'Ingrese un email válido',
									},
								}}
								render={({ field }) => (
									<TextField
										variant="outlined"
										fullWidth
										size="small"
										InputLabelProps={{ shrink: true }}
										className={classes.textField}
										helperText={checkShowErrorMessage(Boolean(errors.email), errors.email?.message)}
										error={Boolean(errors.email)}
										{...field}
									/>
								)}
							/>
						</Grid>
						{isInDb && passenger.ageAtEndTravel != null && (
							<Grid item xs={12} sm={11} className={classes.data}>
								<Typography className={classes.title}>Edad a la fecha de vuelta</Typography>
								<ControlledTextField
									name="ageAtEndTravel"
									rules={{
										required: { value: true, message: 'Este campo es requerido' },
									}}
									helperText={checkShowErrorMessage(Boolean(errors.ageAtEndTravel), errors.ageAtEndTravel?.message)}
								/>
							</Grid>
						)}
					</Grid>
					<Divider orientation="vertical" flexItem sx={{ my: 5, mx: 2 }} />
					<Grid item xs={12} sm={8.5}>
						<Grid container xs={12} sm={12} sx={{ pb: 1, justifyContent: 'space-between' }}>
							<Typography variant="h6" sx={{ ml: 1 }}>
								Documentos
							</Typography>
							<Box display="flex" gap={2}>
								<Button variant="contained" color="success" type="submit">
									<SaveIcon />
								</Button>
								<Button size="small" variant="contained" color="error" onClick={handleRemovePax}>
									<DeleteIcon />
								</Button>

								<ControlledCheckBox name="mainPassenger" label="Pasajero Principal" />
							</Box>
						</Grid>
						{documents.map((document, docIndex) => (
							<Grid container spacing={2} key={docIndex} className={classes.data}>
								<Grid item xs={12} sm={2}>
									{/* 
								// @ts-ignore */}
									<input type="hidden" {...register(`documents.${docIndex}.keyId`)} />
									<Typography className={classes.title}>Tipo</Typography>
									<ControlledSelect
										name={`documents.${docIndex}.documentType`}
										rules={{
											required: { value: true, message: 'Este campo es requerido' },
										}}
										options={passengerDocumentType.map((item) => ({ id: item.id.toString(), name: item.name }))}
									/>
								</Grid>
								<Grid item xs={12} sm={2.5}>
									<Typography className={classes.title}>Origen</Typography>
									<ControlledSelect
										name={`documents.${docIndex}.country`}
										rules={{
											required: { value: true, message: 'Este campo es requerido' },
										}}
										options={typeNationalites.map((item) => ({ id: item.flagsCode.toString(), name: item.name }))}
									/>
								</Grid>
								<Grid item xs={12} sm={2}>
									<Typography className={classes.title}>Numero</Typography>
									<Controller
										name={`documents.${docIndex}.documentNumber` as const}
										control={control}
										rules={{
											required: { value: true, message: 'Este campo es requerido' },
										}}
										render={({ field }) => (
											<TextField
												variant="outlined"
												required
												fullWidth
												size="small"
												InputLabelProps={{ shrink: true }}
												className={classes.textField}
												helperText={checkShowErrorMessage(
													Boolean(errors.documents?.[docIndex]?.documentNumber),
													errors.documents?.[docIndex]?.documentNumber?.message,
												)}
												error={Boolean(errors.documents?.[docIndex]?.documentNumber)}
												{...field}
											/>
										)}
									/>
								</Grid>
								<Grid item xs={12} sm={2.5}>
									<Typography className={classes.title}>Expiración</Typography>
									<ControlledDatePicker
										name={`documents.${docIndex}.expirationDate`}
										rules={{ required: 'Este campo es requerido' }}
										required={true}
									/>
								</Grid>
								<Grid item xs={12} sm={2}>
									<Typography className={classes.title}>Imagen</Typography>
									{/* <DragDrop
                                index={index}
                                docIndex={docIndex}
                                updateImage={updateImage}
                                documentsState={documentsState}
                            /> */}
								</Grid>
								<Grid xs={12} sm={1}>
									<Box
										display="flex"
										alignItems="flex-end"
										// sx={{ border: '1px solid black' }}
										height={70}
										paddingLeft={1}
										gap={1}
									>
										<VisibilityIcon
											sx={{
												cursor: 'pointer',
												color: theme.palette.secondary.main,
											}}
											onClick={() => document.image && window.open(document.image)}
										/>
										<DeleteIcon
											sx={{
												cursor: 'pointer',
												color: theme.palette.error.main,
											}}
											onClick={handleDeleteDocument(docIndex)}
										/>
									</Box>
								</Grid>
							</Grid>
						))}
						<Grid item xs={12} sm={12}>
							<Button className={classes.buttonAdd} variant="outlined" onClick={handleAddDocument}>
								+ Agregar documento
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</FormProvider>
	);
};
